// **** React Imports ****
import React, { useState, useMemo } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { ConfirmPopup, confirmPopup } from 'primereact/confirmpopup';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
// **** External Utilities ****
import {
  Grid,
  Typography,
  Button,
  Paper,
  Divider,
  Tabs,
  Tab,
  Menu,
  MenuItem,
  Collapse,
  TextField,
} from '@material-ui/core';
import { Button as PButton } from 'primereact/button';
import { TabContext, TabPanel } from '@material-ui/lab';
import { useFormik } from 'formik';
import * as Yup from 'yup';

// **** Custom Components ****
import { Sidebar } from 'primereact/sidebar';
import {
  ExpandLess,
  ExpandMore,
  MoreVert as MoreVertIcon,
} from '@material-ui/icons';

import ScheduleInfo from '../ScheduleInfo/ScheduleInfo';
import ProjectItemsNewUI from '../ProjectItemsNewUI/ProjectItems';
import Notes from '../Notes/Notes';
import SmsNotifications from '../SMS/SmsNotifications';
import EmailNotifications from '../EmailNotifications/EmailNotifications';
import WarrantyInfo from '../Warranty/WarrantyInfo';

// import Docs from '../Docs';
import SiteInspections from '../SiteInspections/SiteInspections';
import Extracolumn from '../Extracolumn/Extracolumn';
import EPALead from '../EPALead/EPALead';
import AdditionalFee from '../AdditionalFee/AdditionalFee';
import TimeManagement from '../TimeManagement/TimeManagement';
import Activities from '../Activities/Activities';
import Docs from '../Docs/Docs';
import Photos from '../Docs/Photos';
import { Alert } from '../../shared/Alerts/Alert';
import Permitting from '../Permitting/Permitting';
import NeedSupport from '../NeedSupport/NeedSupport';
import RelatedPO from '../RelatedPO/RelatedPO';
import Issues from '../Issues/Issues';
import Billing from '../Billing/Billing';
import Audit from '../Audit/Audit';
import Footer from '../../shared/Footer/Footer';
import {
  checkPermission,
  isCrmEnabled,
  isMeasureSquareEnabled,
  isMeasureSquareHDLeadEnabled,
  isMeasureSquareHDPOEnabled,
  isWarrantyEnabled,
  lockGlobalBar,
  lockProjectBar,
  shippingAndReceivingModule,
} from '../../../utils/Helpers';
import permissions from '../../../config/permissions';

// **** Services *****
import { getLaborItems } from '../ProjectItemsNew/ProjectItemsService';
import { fetchConfigurationEntities } from '../ProjectFields/FormFieldsConfiguration.service';
import { useAlerts } from '../../shared/Alerts/alertsService';

import {
  getProjectById,
  updateProject,
  updateProjectStatus,
  addProject,
  getCustomerDetails,
  updateProjectContract,
  updateWorkOrder,
  repairOrderRecord,
  actionButton,
  readyToScheduleQuickAction,
  addBustedReason,
} from './ProjectDetails.service';

import '../../../components/TaskManagement/TaskManagement.css';
import TaskManagement from '../../../components/TaskManagement/TaskManagement';
import { ShippingContainer } from '../Shipping/ShippingContainer';

// **** Styles/Images/Icons ****
import { useStyles } from './AddViewEditProjectDetails.styles';

import { updateLatLongForProjectInstallationAddress } from '../ScheduleInfo/ScheduleInfo.service';

import { useSelector, useDispatch } from 'react-redux';

import {
  PROJECT_TYPE,
  ALLOWED_TYPES_FOR_NEED_SUPPORT_TAB,
  SOURCE_SYSTEMS,
  ENVIRONMENT_VARIABLES,
  PROJECT_QUESTIONNAIRE_MODULE_TAG_NAME,
  CONFIG,
  PROJECT_TYPES,
  CLIENT_DETAILS,
  QUICK_ACTION_TYPE,
  PropertyOccupationDropdown,
  DELIVERY_STATUS,
} from '../../../constants';
import GenericDialog from '../../shared/Dialog/GenericDialog';
import ChargeBackTab from '../Chargeback/ChargebackTab';

import { useEffect } from 'react';

import PageHeader from '../../shared/PageHeader/PageHeader';

// **** Helpers ****
import { check100PercentAllocation } from '../../../utils/Helpers';
import { getQuoteById } from '../../CRM/Quotes/helpers/Quotes';
import { getOpportunityById } from '../../CRM/Opportunities/helpers/Opportunity';
import AuditNew from '../Audit/AuditNew';
import InvoiceLising from '../../CRM/Invoice/components/InvoiceListing';
import MeasureSquareQuotes from '../../CRM/Projects/components/MeasureSquareQuotes';
import MSHDPODetails from '../../CRM/Quotes/components/MSHDPODetails';
import MSHDLeadDetails from '../../CRM/Quotes/components/MSHDLeadDetails';
import apiService from '../../../services/api.service';
import ProjectLinkageTable from '../../CRM/ProjectLinkage/ProjectLinkageTable';
import { getProjectCalculations } from '../ProjectItemsNewUI/ProjectItemsService';
import { getClientData } from '../../Admin/NewConfiguration/service/TenantConfig.service';
import AdditionalFields from '../AdditionalFields/AdditionalFields';
import Stages from '../Stages/Stages';

import ActionNoteDialog from './ActionNoteDialog';

import GenericConfirmationDialog from '../../shared/GenericConfirmationDialog/GenericConfirmationDialog';
import PFButton from '../../shared/PFPrime/PFButton';
import { isObject } from '../../../utils/typeof.helper';
import { CLIENT_ID } from '../../../constants/client.constant';

import ProjectInfo from './ProjectInfo';
import ProjectChecklist from './ProjectChecklist';

import { setSideDrawer } from '../../../redux/slices/page-header.slice';

import { Badge } from 'primereact/badge';
import { Tooltip } from 'primereact/tooltip';

import { ReceivingContainer } from '../Receiving/ReceivingContainer';

import {
  needSupportRequiredCondition,
  projectLeadRequiredCondition,
} from './helpers/helpers';

import { Toast } from 'primereact/toast';

import { callPersonalizedProjectNumber } from './PersonalizedProjectNumber';

import { getNeedSupportData } from '../NeedSupport/NeedSupport.service';
const AddViewEditProjectDetails = () => {
  const hideCxScheduling = !!localStorage.getItem('hide_cx_scheduling');
  const dispatch = useDispatch();

  let clientId;
  let history = useHistory();
  //Get projectId and action from url params
  const { quoteId, projectId, actionId, opportunityId, customerId } =
    useParams();

  let action = window.location.pathname.split('/')[2];
  let editLink = `/project/edit/${projectId}`;
  let redirectBasePath = `/project/view/${projectId}`;
  if (
    window.location.pathname.split('/')[5] === 'edit' ||
    window.location.pathname.split('/')[5] === 'view'
  ) {
    action = window.location.pathname.split('/')[5];
    editLink = `/crm/${customerId}/${opportunityId}/project/edit/${projectId}`;
    redirectBasePath = `/crm/${customerId}/${opportunityId}/project/view/${projectId}`;
  }

  // Get report name from search string
  const params = new URLSearchParams(window.location.search);
  // const reportName = params.get('reportName');
  const [reportStatusDetails, setReportStatusDetails] = useState({});
  const reportName = reportStatusDetails.name;
  if (actionId === 'add') {
    action = actionId;
  }
  if (action === 'add') {
    clientId = params.get('clientId');
  }
  const [bustedJobDialogVisible, setBustedJobDialogVisible] = useState(false);
  const [quickActionReq, setQuickActionReq] = useState(null);
  const [getConfigurationEntities, setConfigurationEntities] = useState([]);
  const [projectDetailsData, setProjectDetailsData] = React.useState({});
  const [warehouseLocations, setWarehouseLocations] = React.useState([]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [projectLeadRequired, setProjectLeadRequired] = useState(false);
  const [needSupportRequired, setNeedSupportRequired] = useState({});

  const [customAddressLocations, setCustomAddressLocations] = React.useState([
    {
      name: 'Add New Address',
    },
  ]);

  const [value, setValue] = React.useState('scheduleInfo');
  // To show the error
  const [msProjectInstaller, setMsProjectInstaller] = useState(false);
  const { alert, setAlert, clearAlert } = useAlerts();
  const [visibleSidebar, setVisibleSidebar] = useState(false);
  const [checkProjectPage, setCheckProjectPage] = useState('');
  const [isAddMode, setIsAddMode] = useState(true);
  const [refereshList, setRefereshList] = useState(false);
  const { activeDashboard, activeWidget } = useSelector(
    state => state.pageHeader
  );
  const [refereshLineItems, setRefereshLineItems] = useState(false);
  const [refereshInstallerList, setRefereshInstallerList] = useState(false);
  const [isOpen, setIsOpen] = React.useState(false);
  const [reloadList, setReloadList] = React.useState(false);
  const [dialogSettings, setDialogSettings] = React.useState({
    title: 'Busted Notes',
    showButton2: true,
  });
  const [openPopup, setOpenPopup] = useState(false);
  const [epaLeadReasontoUpdate, setEPALeadReasontoUpdate] = useState(false);
  const toast = React.useRef(null);
  const [projectItemStyle, setProjectItemStyle] = useState(false);
  const [projectItems, setProjectItems] = useState([]);
  const [quoteNumber, setQuoteNumber] = useState('');
  const [opportunityInfo, setOpportunityInfo] = useState({
    opportunityNumber: '',
    opportunityType: '',
    opportunityCategory: '',
    source_system: '',
    leadOwner: '',
    parent_category_id: '',
    source_category_id: null,
  });
  const [totalRevenue, setTotalRevenue] = useState();
  const [isSalesCommissionOpted, setIsSalesCommissionOpted] = useState(false);
  const [commissionedTechncianLineItem, setCommissionedTechncianLineItem] =
    useState(false);
  const [projectInstallers, setProjectInstallers] = useState([]);
  const [projectInstaller, setProjectInstaller] = useState({
    installer_id: null,
    installer_name: null,
    user_id: null,
  });
  const [copyValue, setCopyValue] = useState('');
  const [confirmDialog, setConfirmDialog] = useState({
    header: 'Confirmation',
    title: '',
    subtitle: '',
    isOpen: false,
    button2Text: 'Yes',
    button1Text: 'No',
  });

  const clientDetails = JSON.parse(localStorage.getItem('client_details'));

  const getInstallerFullName = installer => {
    const first_name = installer?.installer_detail?.user?.first_name;
    const last_name = installer?.installer_detail?.user?.last_name;
    const fullName = `${first_name || ''} ${last_name || ''}`;
    return fullName;
  };
  useEffect(async () => {
    if (
      projectDetailsData?.project_number &&
      action === 'copy' &&
      clientDetails?.is_personalized_private_project === 1
    ) {
      const response = await callPersonalizedProjectNumber();
      setCopyValue(response);
    }
  }, [projectDetailsData?.project_number]);
  useEffect(() => {
    projectInstallers.length > 0
      ? setProjectInstaller(projectInstallers)
      : setProjectInstaller({
          installer_id: null,
          installer_name: null,
          user_id: null,
        });
  }, [projectInstallers.length, bustedJobDialogVisible]);
  const [urlEvent, setUrlEvent] = useState({ pre: null, next: null });
  const [urlLink] = useState(
    JSON.parse(
      sessionStorage.getItem(action === 'edit' ? 'editLinks' : 'viewLinks')
    ) || []
  );
  sessionStorage.removeItem('editLinks');
  sessionStorage.removeItem('viewLinks');

  useEffect(() => {
    const [preUrl, nextUrl] = projectActivity(action);
    setUrlEvent({ pre: preUrl, next: nextUrl });
    setValue('scheduleInfo');
    setRefereshInstallerList(true);
  }, [window.location.pathname, action]);

  const projectActivity = (mode = null) => {
    const index = (urlLink || []).findIndex(
      i => i === window.location.pathname
    );
    let nextUrl = null;
    let preUrl = null;
    if (index > -1) {
      nextUrl = urlLink[index + 1] || null;
      preUrl = urlLink[index - 1] || null;
    }
    return [preUrl, nextUrl];
  };

  const [sidebarLayout, setSidebarLayout] = useState(
    localStorage.getItem('sidebar-visibility')
  );
  useEffect(() => {
    const handleStorageChange = () => {
      const updatedVisibility = localStorage.getItem('sidebar-visibility');
      setSidebarLayout(updatedVisibility);
    };
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const responsiveWidth = () => {
    if (width < 768) {
      return '100%';
    }
    return sidebarLayout === 'visible' ? '81.2%' : '95.9%';
  };

  const handleTabChange = (event, newValue) => {
    clearAlert();
    setValue(newValue);
  };

  React.useEffect(() => {
    bustedJobDialogVisible && handleClose(!bustedJobDialogVisible);
  }, [bustedJobDialogVisible]);

  React.useEffect(() => {
    const urlParams = history?.location?.pathname;

    const viewEditParam = urlParams.split('/');
    const isProjectViewEdit = viewEditParam.slice(1, 3).join('/');
    if (
      isProjectViewEdit === 'project/view' ||
      isProjectViewEdit === 'project/edit'
    ) {
      setCheckProjectPage(true);
    } else {
      setCheckProjectPage(false);
    }
  }, [location]);

  React.useEffect(() => {
    getClientData(localStorage.getItem('client_id'))
      .then(clientData => {
        if (clientData?.data?.client)
          setIsSalesCommissionOpted(
            !!clientData?.data?.client?.sales_commission_opted
          );
      })
      .catch();
  }, [localStorage.getItem('client_id')]);

  const typeOfProject = 'projectType';

  const updateTabId = index => {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  };

  const [reloadForm, setReloadForm] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  // State for storing Customer/Client Info
  const [customerDetails, setCustomerDetails] = React.useState([]);
  // State for storing Store Details
  const [storeDetails, setStoreDetails] = React.useState([]);
  const [expand, setExpand] = React.useState(true);
  // Breadcrumb for Add,View or Edit Project
  var projectDetailsBreadcrumb = [];
  const [anchorEl, setAnchorEl] = React.useState(null);
  // State for User Lists
  const [allUserList, setAllUserList] = React.useState([]);
  const [installationAddress, setInstallationAddress] = React.useState('');

  const [isExternalSource, setIsExternalSource] = React.useState(0);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  React.useEffect(() => {
    fetchConfigurationEntities(
      'projects',
      setConfigurationEntities,
      setLoading,
      setAlert
    );
  }, []);

  const [reloadLaborItems, setReloadLaborItems] = useState(true);
  React.useEffect(() => {
    if (reloadLaborItems && formik?.values?.project_id) {
      getLaborItems(formik?.values?.project_id)
        .then(res => {
          if (
            res?.data?.status &&
            res?.data?.status === true &&
            Array.isArray(res?.data?.data)
          ) {
            setCommissionedTechncianLineItem(
              res.data.data.find(
                element =>
                  !!element?.is_commission_item && !!element?.paid_status
              )
            );
            setProjectItems(res?.data?.data);
          }
        })
        .catch(err => {
          setProjectItems([]);
        });
      setReloadLaborItems(false);
    }
  }, [reloadLaborItems]);

  React.useEffect(() => {
    if (projectItems && projectItems?.length > 0) {
      const allocationStatus = check100PercentAllocation(projectItems);
      setProjectItemStyle(!allocationStatus);
    } else {
      setProjectItemStyle(false);
    }
  }, [projectItems]);

  const projectLogo = projectDetailsData?.source_system?.logo_url;
  const projectNumber = projectDetailsData?.project_number;
  const poNumber = projectDetailsData?.po_number;
  const projectType = projectDetailsData?.project_type?.project_type;
  const projectStatus = projectDetailsData?.status?.status;
  const statusType = projectDetailsData?.status?.status_type;
  const projectIdValue = projectDetailsData?.project_activity?.project_id;
  const taskProjectId = projectDetailsData?.project_id;
  const customHeader = (
    <React.Fragment>
      <div className="grid pr-5 w-12 text-center">
        <div className="grid w-12 text-white m-0 text-center justify-content-center">
          <h2 className="m-0">Task Planner</h2>
        </div>
        <div className="grid w-12 text-white m-0">
          <div className="col-12 md:col-4 pb-0">
            Project Number:{' '}
            <strong className="text-white">{projectNumber}</strong>
          </div>

          <div className="col-12 md:col-4 pb-0">
            Internal Status:{' '}
            <strong className="text-white">{projectStatus}</strong>
          </div>
          <div className="col-12 md:col-4 pb-0">
            Type: <strong className="text-white">{projectType}</strong>
          </div>
        </div>
      </div>
    </React.Fragment>
  );

  React.useEffect(() => {
    if (
      projectDetailsData?.project_type?.project_type_id &&
      projectDetailsData?.installation_address?.address_id &&
      (!parseInt(projectDetailsData?.installation_address?.latitude) ||
        !parseInt(projectDetailsData?.installation_address?.longitude))
    ) {
      updateLatLongForProjectInstallationAddress(
        projectDetailsData?.project_id,
        projectDetailsData,
        setProjectDetailsData
      );
    }
  }, [
    projectDetailsData?.project_id,
    projectDetailsData?.project_type?.project_type_id,
    projectDetailsData?.installation_address?.address_id,
    projectDetailsData?.installation_address?.latitude,
    projectDetailsData?.installation_address?.longitude,
  ]);

  React.useEffect(() => {
    if (action !== 'add') {
      if (projectId || reloadForm) {
        getProjectById(
          projectId,
          setLoading,
          setAlert,
          setProjectDetailsData,
          setStoreDetails,
          setCustomerDetails,
          setReportStatusDetails,
          setAllUserList
        );
        getProjectCalculations(projectId)
          .then(result => {
            if (result?.status && result?.data?.total_revenue)
              setTotalRevenue(result?.data?.total_revenue);
          })
          .catch();
      }
      setReloadForm(false);
    } else {
      clientId &&
        getCustomerDetails(
          clientId,
          setLoading,
          setCustomerDetails,
          formik.setFieldValue
        );
      setCustomerDetails([]);
      setStoreDetails([]);
    }
  }, [projectId, reloadForm, reloadList]);

  if (action === 'add') {
    projectDetailsBreadcrumb = [];
  } else if (action === 'copy') {
    projectDetailsBreadcrumb = [
      {
        text: 'Copy Project',
      },
    ];
  } else {
    const activeDashboardSegment = {
      text: activeDashboard?.name,
      link: '/dashboard',
    };

    const activeWidgetSegment = {
      text: activeWidget?.widgetTemplate?.widget_name,
      link:
        activeWidget?.widgetTemplate?.route_widget === 1
          ? '/routing'
          : `/widget/details/${activeWidget?.widgetTemplate?.widget_template_id}`,
    };

    const projectDetailsSegment = {
      text: 'Project Details',
      link: window.location.pathname + window.location.search,
    };

    projectDetailsBreadcrumb = [
      activeDashboardSegment,
      activeWidgetSegment,
      projectDetailsSegment,
    ];
  }
  const { group_source_category_opted } = CLIENT_DETAILS || {};
  const ProjectFormSchema = Yup.object().shape({
    ...(action === 'edit' && {
      project_number: Yup.string()
        .trim()
        .required('Project Number is required'),
    }),
    source_system: Yup.object()
      .shape({
        source_system_id: Yup.number().required(),
        source_name: Yup.string().required(),
      })
      .required('Source Image is required.'),
    project_type: Yup.object().required('Project Type is required.'),
    project_category: Yup.object().when('source_system.source_system_id', {
      is: 2,
      then: Yup.object().optional().nullable(),
      otherwise: Yup.object().shape({
        project_category_id: Yup.number().required(),
        client_id: Yup.string().required(),
        category: Yup.string().required(),
      }),
    }),

    ...((group_source_category_opted || false) && {
      source_category_id: Yup.number()
        .when('project_category_id', {
          is: val => val !== undefined,
          then: Yup.number().required('Required.'),
          otherwise: Yup.number(),
        })
        .nullable(true),
    }),
    customer: Yup.object().required('Client is required.'),
    status: Yup.object().required('Status is required.'),
    store_id: Yup.string().trim().required('Store is required.'),
    hd_category: Yup.string().optional().max(200).nullable(),
    est_job_duration: Yup.number().min(1).max(999).optional().nullable(),
    products: Yup.number().min(1).max(9999).optional().nullable(),
    pickup_required: Yup.number().optional().nullable(),
    pick_up_location_id: Yup.number()
      .nullable(true)
      .when(['pickup_required'], {
        is: pickup_required => pickup_required == 1,
        then: Yup.number().required('Pickup location is required'),
        otherwise: Yup.number().nullable(true),
      }),
    pick_up_location_address_id: Yup.number()
      .nullable(true)
      .when(['pick_up_location_id'], {
        is: pick_up_location_id => [1, 4].includes(pick_up_location_id),
        then: Yup.number().required('Pickup address is required'),
        otherwise: Yup.number().nullable(true),
      }),
    total_sale_amount: Yup.number().nullable(true).optional(),
  });

  const formik = useFormik({
    initialValues: {
      total_sale_amount: projectDetailsData?.total_sale_amount || '0.00',
      quoteId: quoteId || '',
      project_id: action === 'add' ? '' : projectId,
      source_system:
        action === 'add'
          ? opportunityInfo?.source_system || ''
          : projectDetailsData?.source_system,
      project_number:
        action === 'add'
          ? ''
          : action === 'copy'
            ? projectDetailsData?.source_system?.source_name === 'Private' &&
              clientDetails?.is_personalized_private_project === 1
              ? copyValue?.toString() || ''
              : projectDetailsData?.project_number + '_1'
            : projectDetailsData?.project_number,
      po_number:
        action === 'add'
          ? ''
          : action === 'copy'
            ? projectDetailsData?.po_number === null
              ? projectDetailsData?.project_number
              : projectDetailsData?.po_number
            : projectDetailsData?.po_number,
      project_type:
        action === 'add'
          ? typeof opportunityInfo?.opportunityType === 'object'
            ? opportunityInfo?.opportunityType
            : ''
          : projectDetailsData?.project_type,
      project_activity:
        action === 'add'
          ? ''
          : projectDetailsData?.project_activity &&
            projectDetailsData?.project_activity,
      project_category:
        action === 'add'
          ? typeof opportunityInfo?.opportunityCategory === 'object'
            ? opportunityInfo?.opportunityCategory
            : ''
          : projectDetailsData?.project_category,
      project_category_id:
        action === 'add'
          ? opportunityInfo?.parent_category_id
            ? opportunityInfo?.parent_category_id
            : ''
          : projectDetailsData?.project_category_id,
      source_category_id:
        action === 'add'
          ? opportunityInfo?.opportunityCategory
            ? opportunityInfo?.source_category_id
            : null
          : projectDetailsData?.source_category_id,
      customer:
        action === 'add'
          ? customerDetails.length > 0
            ? customerDetails[0]
            : ''
          : projectDetailsData?.customer,
      store_id: action === 'add' ? '' : projectDetailsData?.store_id,
      status: action === 'add' ? '' : projectDetailsData?.status,
      imsStatus: action === 'add' ? '' : projectDetailsData?.source_status,
      confirmation_status:
        action === 'add' || action === 'copy'
          ? ''
          : projectDetailsData?.confirmation_status,
      source_status:
        action === 'add' || action === 'copy'
          ? ''
          : projectDetailsData?.source_status,
      date_sold:
        action === 'add' || action === 'copy'
          ? ''
          : projectDetailsData?.date_sold,
      project_desc: action === 'add' ? '' : projectDetailsData?.project_desc,
      tier:
        action === 'add' || action === 'copy' ? '' : projectDetailsData?.tier,
      est_job_duration:
        action === 'add' || action === 'copy'
          ? ''
          : projectDetailsData?.est_job_duration,
      year_built:
        action === 'add' || action === 'copy'
          ? ''
          : projectDetailsData?.year_built,
      pickup_required:
        action === 'add' || action === 'copy'
          ? '0'
          : projectDetailsData?.pickup_required === null
            ? 0
            : projectDetailsData?.pickup_required,
      products:
        action === 'add' || action === 'copy'
          ? ''
          : projectDetailsData?.products,
      current_activity:
        action === 'add' || action === 'copy'
          ? ''
          : projectDetailsData?.current_activity,
      current_activity_status:
        action === 'add' || action === 'copy'
          ? ''
          : projectDetailsData?.current_activity_status,
      quote_number:
        action === 'add'
          ? quoteNumber
          : projectDetailsData?.project_crm?.quote_number,
      opportunity_number:
        action === 'add'
          ? opportunityInfo?.opportunityNumber
          : projectDetailsData?.project_crm?.opportunity_number,
      work_order_number:
        action === 'add' ? '' : projectDetailsData?.work_order_number,
      open_in_provider_portal:
        action === 'add' || action === 'copy'
          ? ''
          : projectDetailsData?.open_in_provider_portal,
      manage_as_project:
        action === 'add' ? 0 : projectDetailsData?.manage_as_project,
      project_additional_fields: projectDetailsData?.project_additional_fields,
      referral_source_id:
        action === 'add' ? '' : projectDetailsData?.referral_source_id,
      ll_invoice_sent_date:
        action === 'add'
          ? ''
          : projectDetailsData?.project_additional_fields?.ll_invoice_sent_date,
      ll_invoice_status:
        action === 'add'
          ? ''
          : projectDetailsData?.project_additional_fields?.ll_invoice_status,
      ll_pgi_invoice_date:
        action === 'add'
          ? ''
          : projectDetailsData?.project_additional_fields?.ll_pgi_invoice_date,
      sales_commission:
        action === 'add'
          ? ''
          : projectDetailsData?.project_additional_fields?.sales_commission,
      estimated_labour_amount:
        action === 'add'
          ? ''
          : projectDetailsData?.project_additional_fields
              ?.estimated_labour_amount,
      cushion_payout:
        action === 'add'
          ? '00.0'
          : projectDetailsData?.project_additional_fields?.cushion_payout ||
            '0.00',
      project_color_config:
        projectDetailsData?.project_additional_fields?.project_color_code
          ?.color_config_id ||
        projectDetailsData?.project_type?.project_type_colors_map?.color_config
          ?.color_config_id,
      color_name:
        projectDetailsData?.project_additional_fields?.project_color_code
          ?.color_name ||
        projectDetailsData?.project_type?.project_type_colors_map?.color_config
          ?.color_name,
      property_occupation:
        action === 'add'
          ? ''
          : projectDetailsData?.project_additional_fields?.property_occupation,
      digital_detail_url:
        action === 'add'
          ? ''
          : projectDetailsData?.project_additional_fields?.digital_detail_url,
      workroom_id:
        action === 'add'
          ? ''
          : action === 'edit' || action === 'view'
            ? projectDetailsData?.workroom_id
              ? projectDetailsData?.workroom_id
              : storeDetails?.[0]?.workroom_type_id
            : storeDetails?.[0]?.workroom_type_id,

      /**Schedule Info Tab fields **/
      installation_address:
        action === 'add'
          ? installationAddress || ''
          : projectDetailsData?.installation_address,
      reschedule_reason:
        action === 'add' || action === 'copy'
          ? ''
          : projectDetailsData?.reschedule_reason,
      date_scheduled_start:
        action === 'add' || action === 'copy'
          ? null
          : projectDetailsData?.date_scheduled_start,
      date_scheduled_end:
        action === 'add' || action === 'copy'
          ? null
          : projectDetailsData?.date_scheduled_end,
      completion_date:
        action === 'add' || action === 'copy'
          ? null
          : projectDetailsData?.completion_date,
      rts_follow_up_date:
        action === 'add' || action === 'copy'
          ? null
          : projectDetailsData?.rts_follow_up_date,
      pick_up_date:
        action === 'add' || action === 'copy'
          ? null
          : projectDetailsData?.pick_up_date,
      windows:
        action === 'add' || action === 'copy'
          ? null
          : projectDetailsData?.windows,
      pick_up_location_id:
        action === 'add' || action === 'copy'
          ? null
          : projectDetailsData?.pick_up_location_id,
      pick_up_location_address_id:
        action === 'add' || action === 'copy'
          ? null
          : projectDetailsData?.pick_up_location_address_id,
      installer_reference:
        action === 'add' || action === 'copy'
          ? null
          : projectDetailsData?.installer_reference,
      move_up_on_schedule:
        action === 'add' || action === 'copy'
          ? null
          : projectDetailsData?.move_up_on_schedule,
      confirm_appointment: projectDetailsData?.confirm_appointment,
      dd_confirm: projectDetailsData?.dd_confirm,
      dd_confirm_follow_up: projectDetailsData?.dd_confirm_follow_up,
      project_floorsoft:
        action === 'add'
          ? ''
          : projectDetailsData?.project_floorsoft &&
            projectDetailsData?.project_floorsoft,
      lowes_delivery_date: projectDetailsData?.lowes_delivery_date,
      lowes_delivery_sold: projectDetailsData?.lowes_delivery_sold,
      scheduling_reason:
        action === 'add' || action === 'copy'
          ? ''
          : projectDetailsData?.project_additional_fields?.scheduling_reason,
      schedule_pending_reason:
        action === 'add' || action === 'copy'
          ? ''
          : projectDetailsData?.project_additional_fields
              ?.schedule_pending_reason,
      vendor_id:
        action === 'add' || action === 'copy'
          ? null
          : projectDetailsData.vendor_id,
      vendor_phone:
        action === 'add' || action === 'copy'
          ? null
          : projectDetailsData.project_vendor?.phone,
      vendor_tracking_link:
        action === 'add' || action === 'copy'
          ? null
          : projectDetailsData.project_vendor?.tracking_link,
      sos_po_vendor:
        action === 'add' || action === 'copy'
          ? null
          : projectDetailsData.sos_po_vendor,

      /**EPA Lead Tab **/
      epa_add_lead_safe_practices_item:
        projectDetailsData?.epa_add_lead_safe_practices_item,
      epa_is_lead_assessment_docs_submitted:
        projectDetailsData?.epa_is_lead_assessment_docs_submitted,
      epa_is_renovator_certified:
        projectDetailsData?.epa_is_renovator_certified,
      epa_lead_assessment_required:
        projectDetailsData?.epa_lead_assessment_required,
      epa_lead_remediation: projectDetailsData?.epa_lead_remediation,
      epa_lead_response_reason_id:
        projectDetailsData?.epa_lead_response_reason_id,
      // EPA Fields for Home Depot
      epa_job_id: projectDetailsData?.epa_job_id,
      epa_remove_override: projectDetailsData?.epa_remove_override,
      epa_permit_number: projectDetailsData?.epa_permit_number,
      epa_permit_required: projectDetailsData?.epa_permit_required,
      epa_lead_test_result_id: projectDetailsData?.epa_lead_test_result_id,
      epa_override_reason_code_id:
        projectDetailsData?.epa_override_reason_code_id,
      meta: projectDetailsData?.meta || {},
      review_status: projectDetailsData?.review_status,
      scheduled_date_811: projectDetailsData?.scheduled_date_811 || null,
      is_pull_permit_required: projectDetailsData?.is_pull_permit_required,
      lswp_required: projectDetailsData?.lswp_required,
      lead_test_req: projectDetailsData?.lead_test_req,
      lswp_followed: projectDetailsData?.lswp_followed,
      hd_lead_test_result: projectDetailsData?.hd_lead_test_result,
      hd_category: projectDetailsData?.hd_category,
      related_measurer:
        action === 'add' || action === 'copy'
          ? ''
          : projectDetailsData?.project_additional_fields?.related_measurer,
      //ETA Date
      eta_date:
        action === 'add' || action === 'copy'
          ? null
          : projectDetailsData?.eta_date,
      //Additional Fields
      wo_triage_id:
        action === 'add' || action === 'copy'
          ? ''
          : projectDetailsData?.project_additional_fields?.wo_triage_id,
      wo_original_install_date:
        action === 'add' || action === 'copy'
          ? null
          : projectDetailsData?.project_additional_fields
              ?.wo_original_install_date,
      wo_wfp_eta:
        action === 'add' || action === 'copy'
          ? null
          : projectDetailsData?.project_additional_fields?.wo_wfp_eta,
      wo_original_cause_id:
        action === 'add' || action === 'copy'
          ? 'Cause'
          : projectDetailsData?.project_additional_fields?.wo_original_cause_id,
      wo_explaination:
        action === 'add' || action === 'copy'
          ? ''
          : projectDetailsData?.project_additional_fields?.wo_explaination,
      wo_reason_id:
        action === 'add' || action === 'copy'
          ? ''
          : projectDetailsData?.project_additional_fields?.wo_reason_id,
      special_tool: projectDetailsData?.special_tool === 1,
    },
    validationSchema: ProjectFormSchema,
    validate: values => {
      let error = {};
      if (!values?.installation_address?.address1) {
        error.installation_address = {
          ...error.installation_address,
          address1: 'Address field is Required.',
        };
      }
      if (!values?.installation_address?.city) {
        error.installation_address = {
          ...error.installation_address,
          city: 'City field is Required.',
        };
      }
      if (!values?.installation_address?.zipcode) {
        error.installation_address = {
          ...error.installation_address,
          zipcode: 'Zipcode field is Required.',
        };
      }
      return error;
    },
    onSubmit: values => {
      if (action === 'edit') {
        setLoading(true);
        updateProject(
          projectId,
          values,
          history,
          setReloadForm,
          setLoading,
          setAlert,
          redirectBasePath
        );
      } else {
        addProject(
          values,
          history,
          reportName,
          setLoading,
          setAlert,
          opportunityId
        );
      }
    },
    enableReinitialize: true,
  });
  useEffect(() => {
    if (quoteId) {
      const quoteDetails = getQuoteById(clientId, opportunityId, quoteId);

      quoteDetails
        .then(res => {
          const quoteNumber = res?.data?.data?.quoteNumber;
          setQuoteNumber(res?.data?.data?.quoteNumber || '');
        })
        .catch(error => {
          if (error?.response?.status === 404) {
            return history.push('/page-not-found');
          }
          console.log('error', error?.response);
        });
    }
  }, [quoteId]);

  React.useEffect(() => {
    clearAlert();
  }, [action]);

  const unAuthorizedAccessHandler = () => {
    history.push(`/access-denied`);
  };

  // Pre-populate all the fields in view or edit mode
  React.useEffect(() => {
    if (action !== 'add') {
      if (projectId || reloadForm) {
        getProjectById(
          projectId,
          setLoading,
          setAlert,
          setProjectDetailsData,
          setStoreDetails,
          setCustomerDetails,
          setReportStatusDetails,
          setAllUserList,
          unAuthorizedAccessHandler
        );
      }
      setReloadForm(false);
    } else {
      clientId &&
        getCustomerDetails(
          clientId,
          setLoading,
          setCustomerDetails,
          formik.setFieldValue,
          setInstallationAddress
        );
      setCustomerDetails([]);
      setStoreDetails([]);
    }
  }, [projectId, reloadForm]);

  const accept = () => {
    if (value !== 'epaLead') {
      toast.current.show({
        severity: 'info',
        summary: 'Mandatory Fields Pending',
        detail: 'You are redirected to EPA Lead tab',
        life: 2000,
      });
    }
    setValue('epaLead');
    setEPALeadReasontoUpdate(true);
  };
  const reject = () => {
    setEPALeadReasontoUpdate(false);
  };

  const handleStatusButtonClick = status => {
    setAnchorEl(null);
    if (
      status === 'completed' &&
      clientDetails?.epa_guardrails_workflow === 1
    ) {
      const isLeadRequiredShowAlert =
        projectDetailsData.source_system_id === 2
          ? showIsLeadRequiredAlertHD()
          : showIsLeadRequiredAlertLowes();
      if (isLeadRequiredShowAlert) {
        setOpenPopup(true);
      } else {
        updateProjectStatus(projectId, status, setReloadForm, setLoading);
      }
    } else {
      updateProjectStatus(projectId, status, setReloadForm, setLoading);
    }
  };

  const showIsLeadRequiredAlertLowes = () => {
    if (
      (projectDetailsData?.epa_lead_assessment_required === 1 ||
        projectDetailsData?.epa_lead_remediation === 1) &&
      projectDetailsData?.project_lead_response_reason === null
    ) {
      return true;
    } else {
      return false;
    }
  };
  const showIsLeadRequiredAlertHD = () => {
    if (
      (projectDetailsData?.epa_lead_assessment_required === 1 ||
        projectDetailsData?.epa_lead_remediation === 1) &&
      projectDetailsData?.epa_override_reason_code_id === null
    ) {
      return true;
    } else {
      return false;
    }
  };

  const handleContractButtonClick = status => {
    setAnchorEl(null);
    updateProjectContract(projectId, status, setReloadForm, setLoading);
  };

  const sendToFllorsoft = () => {
    const result = apiService.post(`/projects/create-floorsoft-project`, {
      project_id: projectId,
    });

    result
      ?.then(res => {
        setAlert('success', res?.message, false, true);
        return result;
      })
      .catch(err => {
        setAlert('error', res?.message, false, true);
      });
  };

  const pullInstalltionLineItems = () => {
    const result = apiService.postWithDiffBaseUrl(
      `/api/measure-square/installtion/line-items`,
      { project_id: projectId },
      CONFIG?.API_CRM_URL
    );

    result
      ?.then(res => {
        setAlert('success', res?.message, false, true);

        return result;
      })
      .catch(err => {
        setAlert('error', res?.message, false, true);
      });
  };

  const handleWorkOrderButtonClick = status => {
    setAnchorEl(null);
    updateWorkOrder(projectId, status, setReloadForm, setLoading);
  };

  const canViewExtraColumnsTab = () => {
    const data = JSON.parse(localStorage.getItem('client_details'));
    return data?.client_id === '06PF01CI';
  };

  useEffect(() => {
    if (opportunityId || projectDetailsData?.project_crm?.opportunity_id) {
      const opportunityDetails = getOpportunityById(
        customerId || clientId || projectDetailsData?.customer_id,
        opportunityId || projectDetailsData?.project_crm?.opportunity_id
      );

      opportunityDetails
        .then(res => {
          if (res.status === 200) {
            setIsExternalSource(
              res?.data?.from_external_source || res?.data?.from_private_lead
            );
            setOpportunityInfo(preValue => ({
              ...preValue,
              opportunityNumber: res?.data?.opportunityNumber || '',
              opportunityType: res?.data?.typeId || '',
              opportunityCategory:
                {
                  project_category_id: res?.data?.categoryId,
                  category: res?.data?.category,
                  client_id: customerId || clientId,
                } || '',
              leadOwner: res?.data?.leadOwner || '',
              parent_category_id: res?.data?.parent_category_id || '',
              source_category_id: res?.data?.source_category_id || null,
            }));
          }
        })
        .catch(error => {
          console.error('error', error.message);
        });
    }
  }, [
    customerId,
    opportunityId,
    projectDetailsData?.project_crm?.opportunity_id,
  ]);

  useEffect(() => {
    if (opportunityInfo) {
      formik.setFieldValue(
        'source_category_id',
        opportunityInfo?.source_category_id || null
      );
    }
  }, [opportunityInfo]);

  let projectTabs = [
    {
      value: 'scheduleInfo',
      name: 'Schedule Info',
      visible: checkPermission(permissions.viewEditProject.tabScheduleInfoView),
      component: (
        <ScheduleInfo
          historyProp={history}
          setReloadFormProp={setReloadForm}
          setLoadingProp={setLoading}
          loadingProp={loading}
          setAlertProp={setAlert}
          formik={formik}
          action={action}
          installerList={projectDetailsData?.project_installer}
          setBackdropLoading={setLoading}
          projectId={projectId}
          projectStatus={projectDetailsData?.status}
          setReloadLaborItems={setReloadLaborItems}
          isSalesCommissionOpted={isSalesCommissionOpted}
          commissionedTechncianLineItem={commissionedTechncianLineItem}
          ddConfirm={projectDetailsData?.dd_confirm}
          setMsProjectInstaller={setMsProjectInstaller}
          distance={projectDetailsData?.distance}
          projectInstallationAddress={projectDetailsData?.installation_address}
          setProjectInstallers={setProjectInstallers}
          bustedDetails={projectDetailsData?.project_busted_details || null}
          refereshInstallerList={refereshInstallerList}
          setRefereshInstallerList={setRefereshInstallerList}
        />
      ),
    },
    {
      value: 'additionalFields',
      name: 'Additional Fields',
      visible:
        action !== 'add' &&
        formik?.values?.project_type?.project_type_id ===
          PROJECT_TYPE.WORKORDER &&
        checkPermission(permissions.viewEditProject.tabScheduleInfoView),
      component: <AdditionalFields formik={formik} action={action} />,
    },
    {
      value: 'extraColumn',
      name: 'Extra Column',
      visible: action !== 'add' && canViewExtraColumnsTab(),
      component: (
        <Extracolumn
          projectId={formik?.values?.project_id}
          projectDetailsData={projectDetailsData}
          loading={loading}
        />
      ),
    },
    /* {
      value: 'siteInspections',
      name: 'Site Inspections',
      visible:
        action !== 'add' && checkPermission(permissions.viewEditProject.tabSiteInspectionView),
      component: <SiteInspections projectId={formik?.values?.project_id} />,
    }, */
    {
      value: 'questionnaire',
      name: 'Checklist',
      visible:
        action !== 'add' &&
        checkPermission(permissions.questionnaire.projectViewAnswer),
      component: <ProjectChecklist targetId={formik?.values?.project_id} />,
    },
    {
      value: 'process',
      name: 'Process',
      visible:
        action !== 'add' &&
        checkPermission(permissions?.viewEditProject?.tabProcessTabView),
      component: <Stages projectId={projectId} />,
    },
    {
      value: 'additionalFee',
      name: 'Additional Fee',
      visible:
        action !== 'add' &&
        checkPermission(permissions?.viewEditProject?.viewAdditionalFeeTab),
      component: <AdditionalFee projectId={formik?.values?.project_id} />,
    },
    ...(isCrmEnabled() && !isMeasureSquareEnabled(isExternalSource)
      ? [
          {
            value: 'Invoices',
            name: 'Invoices',
            visible: action !== 'add',
            component: <InvoiceLising projectId={formik?.values?.project_id} />,
          },
        ]
      : []),
    ...(isMeasureSquareEnabled(isExternalSource)
      ? [
          {
            value: 'Quotes',
            name: 'Quotes',
            visible: action !== 'add',
            component: (
              <MeasureSquareQuotes
                quoteId={projectDetailsData?.project_crm?.quote_id}
                msProjectInstaller={msProjectInstaller}
                projectDetails={projectDetailsData}
              />
            ),
          },
        ]
      : []),
    {
      value: 'Home Depot PO',
      name: 'Home Depot PO',
      visible:
        action !== 'add' &&
        checkPermission(permissions.viewEditProject.tabHomeDepotPoView),
      component: <MSHDPODetails projectId={projectId} />,
    },
    {
      value: 'Home Depot Lead',
      name: 'Home Depot Lead',
      visible:
        action !== 'add' &&
        checkPermission(permissions.viewEditProject.tabHomeDepotLeadView),
      component: <MSHDLeadDetails projectId={projectId} />,
    },
    {
      value: 'projectItemsNewUI',
      name: 'Projects Items',
      visible:
        action !== 'add' &&
        checkPermission(permissions.viewEditProject.tabProjectItems),
      component: (
        <ProjectItemsNewUI
          formik={formik}
          action={action}
          setProjectItems={setProjectItems}
          opportunityId={opportunityId}
          customerDetails={customerDetails}
          storeDetails={storeDetails}
          totalSaleAmount={projectDetailsData?.total_sale_amount}
          isMeasureSquareEnabled={isMeasureSquareEnabled(isExternalSource)}
          setTotalRevenue={setTotalRevenue}
          isSalesCommissionOpted={isSalesCommissionOpted}
          refereshLineItems={refereshLineItems}
          setRefereshLineItems={setRefereshLineItems}
        />
      ),
      // style: projectItemStyle ? { backgroundColor: '#242db1', color: 'red' } : {},
      className: projectItemStyle ? 'highlight-project-item' : null,
    },
    {
      value: 'notes',
      name: (
        <span className="relative inline-block mr-2 mt-1">
          Notes
          {projectDetailsData?.client_info?.highlight_store_notes &&
          projectDetailsData?.store_notes_count ? (
            <>
              <Tooltip target=".store_notes_count" />
              <Badge
                data-pr-tooltip="Total Store Type Notes"
                data-pr-position="top"
                className="store_notes_count"
                value={projectDetailsData?.store_notes_count}
                severity="danger"
                style={{
                  position: 'absolute',
                  top: '-16px',
                  right: '-6px',
                  transform: 'translateX(50%)',
                  padding: '0',
                  color: '#fff',
                }}
              />
            </>
          ) : null}
        </span>
      ),
      visible:
        action !== 'add' &&
        checkPermission(permissions.viewEditProject.tabNotes),
      component: <Notes formik={formik} action={action} />,
      className:
        projectDetailsData?.client_info?.highlight_store_notes &&
        projectDetailsData?.store_notes_count &&
        projectDetailsData?.no_action_store_notes_count
          ? 'bg-pink-100'
          : null,
    },
    {
      value: 'relatedPONew',
      name: "Related PO's",
      visible:
        action !== 'add' &&
        checkPermission(permissions.viewEditProject.tabRelatedPOs),
      component: (
        <ProjectLinkageTable
          currentProject={projectId}
          customerDetails={customerDetails}
          setAlert={setAlert}
        />
      ),
      className: projectItemStyle ? 'highlight-project-item' : null,
    },
    {
      value: 'docs',
      name: 'Docs',
      visible:
        action !== 'add' &&
        checkPermission(permissions.viewEditProject.tabDocs),
      component: (
        <Docs
          projectId={formik?.values?.project_id}
          formik={formik}
          setReloadForm={setReloadForm}
          setAlert={setAlert}
          projectLeadRequired={projectLeadRequired}
          projectDetailsData={projectDetailsData}
          setOpenPopup={setOpenPopup}
          setEPALeadReasontoUpdate={setEPALeadReasontoUpdate}
          clientDetails={clientDetails}
        />
      ),
    },
    {
      value: 'photos',
      name: 'Photos',
      visible:
        action !== 'add' &&
        checkPermission(permissions.viewEditProject.tabPhotosView),
      component: (
        <Photos
          projectId={formik?.values?.project_id}
          formik={formik}
          setReloadForm={setReloadForm}
          projectLeadRequired={projectLeadRequired}
          projectDetailsData={projectDetailsData}
          setOpenPopup={setOpenPopup}
          setEPALeadReasontoUpdate={setEPALeadReasontoUpdate}
          clientDetails={clientDetails}
        />
      ),
    },
    {
      value: 'epaLead',
      name: 'EPA Lead',
      visible:
        action !== 'add' &&
        checkPermission(permissions.viewEditProject.tabEPALead) &&
        formik?.values?.source_system?.source_system_id !==
          SOURCE_SYSTEMS.LUMBER_LIQUIDATOR,
      component: (
        <EPALead
          formik={formik}
          history={history}
          projectId={projectId}
          reportName={reportName}
          action={action}
          checkPermission={checkPermission}
          projectDetailsData={projectDetailsData}
          setReloadForm={setReloadForm}
          epaLeadReasontoUpdate={epaLeadReasontoUpdate}
          setEPALeadReasontoUpdate={setEPALeadReasontoUpdate}
          setOpenpopup={setOpenPopup}
          setAlert={setAlert}
          redirectBasePath={redirectBasePath}
        />
      ),
    },
    {
      value: 'warranty',
      name: 'Warranty',
      visible: action !== 'add' && isWarrantyEnabled(),
      component: <WarrantyInfo projectId={formik?.values?.project_id} />,
    },
    {
      value: 'permitting',
      name: 'Permitting',
      visible:
        action !== 'add' &&
        checkPermission(permissions.viewEditProject.tabPermitting) &&
        formik?.values?.source_system?.source_system_id !==
          SOURCE_SYSTEMS.LUMBER_LIQUIDATOR,
      component: <Permitting formik={formik} action={action} />,
    },

    {
      value: 'billing',
      name: 'Billing',
      visible:
        action !== 'add' &&
        checkPermission(permissions.viewEditProject.tabBilling),
      component: <Billing formik={formik} />,
    },
    {
      value: 'timeManagement',
      name: 'Time Management',
      visible:
        action !== 'add' &&
        checkPermission(permissions.viewEditProject.tabTimeManagement),
      component: <TimeManagement formik={formik} />,
    },
    {
      value: 'activities',
      name: 'Activities',
      visible:
        action !== 'add' &&
        checkPermission(permissions.viewEditProject.tabActivities) &&
        formik?.values?.source_system?.source_system_id !==
          SOURCE_SYSTEMS.LUMBER_LIQUIDATOR,
      component: <Activities formik={formik} />,
    },
    {
      value: 'needSupport',
      name: 'Need Support',
      visible:
        action !== 'add' &&
        formik?.values?.source_system?.source_name === 'Lowes' &&
        checkPermission(permissions.viewEditProject.tabProjectSupportView) &&
        ALLOWED_TYPES_FOR_NEED_SUPPORT_TAB?.includes(
          formik?.values?.project_type?.project_type
        ),
      component: <NeedSupport formik={formik} setReloadForm={setReloadForm} />,
    },
    {
      value: 'issues',
      name: 'Issues',
      visible:
        action !== 'add' &&
        checkPermission(permissions.viewEditProject.tabIssuesView) &&
        formik?.values?.source_system?.source_system_id !==
          SOURCE_SYSTEMS.LUMBER_LIQUIDATOR,
      component: <Issues formik={formik} />,
    },
    {
      value: 'audit',
      name: 'Audit',
      visible:
        action !== 'add' &&
        checkPermission(permissions.viewEditProject.tabAudit),
      component: <AuditNew formik={formik} />,
      isAuditEnabled: parseInt(ENVIRONMENT_VARIABLES.auditTabEnabled),
    },
    {
      value: 'sms',
      name: 'SMS',
      visible:
        action !== 'add' &&
        checkPermission(permissions?.viewEditProject?.tabSMS),
      component: (
        <SmsNotifications formik={formik} customerDetails={customerDetails} />
      ),
    },
    {
      value: 'email',
      name: 'Email',
      visible:
        action !== 'add' &&
        checkPermission(permissions?.viewEditProject?.tabEmail),
      component: (
        <EmailNotifications
          formik={formik}
          storeDetails={storeDetails}
          setAlert={setAlert}
          customerDetails={customerDetails}
        />
      ),
    },
    {
      value: 'chargeback',
      name: 'Chargeback',
      visible: action !== 'add',
      component: <ChargeBackTab projectId={projectId} />,
    },

    ...(shippingAndReceivingModule()
      ? [
          {
            value: 'shipping',
            name: 'Shipping',
            visible: action !== 'add',
            component: <ShippingContainer />,
          },
          {
            value: 'receiving',
            name: 'Receiving',
            visible: action !== 'add',
            component: <ReceivingContainer />,
          },
        ]
      : []),
  ];

  const classes = useStyles({ status: formik?.values?.status?.status });

  projectTabs = projectTabs
    .map(obj => {
      if (obj?.isAuditEnabled === 1 || obj.isAuditEnabled === undefined) {
        return obj;
      } else if (obj?.isAuditEnabled === 0) {
        return false;
      }
    })
    ?.filter(Boolean);
  React.useEffect(() => {
    const tab = projectTabs.find(
      i => `#tab=${i.value}` === window.location.hash
    );
    // const indexToSet = index === -1 ? 0 : index;
    setValue(tab ? tab.value : 'scheduleInfo');
  }, [window.location.hash]);
  // Set Browser Tab Title
  const setBrowserTab = () => {
    if (action == 'view' || action == 'edit') {
      projectDetailsData && projectDetailsData?.project_number
        ? (document.title = `${projectDetailsData?.customer?.first_name} ${projectDetailsData?.customer?.last_name} [${projectDetailsData?.project_number}] `)
        : (document.title = 'ProjectsForce');
    } else {
      document.title = 'ProjectsForce';
    }
  };
  React.useEffect(() => {
    setBrowserTab();
    // Clean up by resetting the title when the component unmounts
    return () => {
      document.title = 'ProjectsForce';
    };
  }, [projectDetailsData]);
  const NotesFormikSchema = Yup.object().shape({
    note_text: Yup.string().trim().required('Note is required.'),

    installers: Yup.array().when([], {
      is: () => quickActionReq === QUICK_ACTION_TYPE?.BUSTED_JOB,
      then: Yup.array()
        .min(1, 'At least one technician must be selected.')
        .required('This field is required.'),
      otherwise: Yup.array(),
    }),
  });
  const quickActionReadyToSchedule = async (id, values) => {
    setLoading(true);
    const res = await readyToScheduleQuickAction(id, values);

    if (res?.status) {
      setRefereshLineItems(true);
      setRefereshInstallerList(true);
      setAlert('success', 'Update Status Successfully.', false, true);
    } else {
      setAlert('error', 'Error in Update Status.', false, true);
      setLoading(false);
    }
  };
  const notesFormik = () => {
    const notesFormik = useFormik({
      enableReinitialize: true,
      initialValues: {
        note_text: '',
        update_technician_worktype: false,
        wipe_off_project_item: false,
        installers: [],
      },
      validationSchema: NotesFormikSchema,
      onSubmit: values => {
        setReloadList(true);
        setBustedJobDialogVisible(false);
        if (quickActionReq === QUICK_ACTION_TYPE?.BUSTED_JOB) {
          addBustedReason(
            formik?.values?.project_id,
            values,
            setLoading,
            setAlert,
            setReloadList,
            setIsOpen,
            setRefereshLineItems
          );
          notesFormik.resetForm();
          setReloadList(false);
        }
        if (quickActionReq === QUICK_ACTION_TYPE?.READY_TO_SCHEDULE) {
          quickActionReadyToSchedule(formik?.values?.project_id, values);
          notesFormik.resetForm();
          setReloadList(false);
        }
      },
    });
    return notesFormik;
  };

  const handleRepairOrderClick = async () => {
    try {
      setLoading(true);
      const response = await repairOrderRecord(formik?.values?.project_id);
      if (response?.status === 200) {
        setAlert(
          'success',
          `Repair Order creation is in progress, please validate after some time in Related PO's.`,
          false,
          true
        );
      } else {
        setAlert('error', 'Error while creating Repair order', false, true);
      }
    } catch (error) {
      setAlert('error', 'Error while creating Repair order', false, true);
    } finally {
      setLoading(false);
    }
  };

  const handleActionType = async type => {
    try {
      setLoading(true);
      let payload = {};
      if (type === 'sync_status') {
        payload = {
          project_number: projectDetailsData?.project_number,
          store_number: projectDetailsData?.store?.store_number,
        };
      }
      const response = await actionButton(projectId, type, payload);
      if (response?.status === true) {
        setReloadForm(true);
        if (type === 'Requalify') {
          setRefereshInstallerList(true);
        }
        setAlert('success', response?.message, false, true);
      } else {
        setAlert(
          'error',
          response?.message || 'Something went wrong!',
          false,
          true
        );
      }
    } catch (error) {
      setAlert('error', 'Something went wrong!', false, true);
    } finally {
      setAnchorEl(null);
      setLoading(false);
      setReloadForm(false);
      if (type === 'Requalify') {
        setRefereshInstallerList(false);
      }
    }
  };

  const readyToScheduleAction = () => {
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title: `This action would change the project from ${
        formik?.values?.status?.status || ''
      } to Ready To Schedule. Would you like to continue ?`,
    });
  };
  const [noteVisible, setNoteVisible] = useState(false);
  const onConfirmDialog = async () => {
    setConfirmDialog(pre => ({ ...pre, isOpen: false }));
    setBustedJobDialogVisible(true);
  };

  const [
    hasCtaViewProjectPermission,
    hasEditProjectPermission,
    hasActionLeftMessagePermission,
    hasActionOnHoldPermission,
    hasActionCompletedPermission,
    hasActionNeedReviewPermission,
    hasActionCancelledPermission,
    hasActionButtonSchedulerPermission,
    hasActionContractPendingPermission,
    hasActionContractReviewedPermission,
    hasAddProjectPermission,
    hasActionBustedJobPermission,
    hasActionReadyToSchedulePermission,
    hasActionSendToFloorsoftPermission,
    hasActionPullLineItemsPermission,
    hasActionAcceptJobPermission,
    hasActionDeclineJobPermission,
    hasActionRepairOrderPermission,
    hasActionCarpetStagedViewPermission,
    hasActionPadStagedViewPermission,
    hasActionFollowUpAmCallViewPermission,
    hasActionFollowUpPmCallViewPermission,
    hasActionWelcomeLeftMessageViewPermission,
    hasActionWelcomeCallCompleteViewPermission,
    hasActionDispatchedViewPermission,
    hasActionEtaConfirmedViewPermission,
    hasActionsInProgressViewPermission,
    hasActionsConfirmCompleteViewPermission,
    hasActionQaCompleteViewPermission,
    hasActionRefundReviewedViewPermission,
    hasActionSurveyResolvedViewPermission,
    hasActionAcknowledgeChangeViewPermission,
    hasActionAcknowledgeCancelViewPermission,
    hasActionRequalifyViewPermission,
    hasActionSyncStatusPermission,
  ] = useMemo(() => {
    return [
      checkPermission(permissions.project.ctaViewProject),
      checkPermission(permissions.project.editProject),
      checkPermission(permissions.viewEditProject.actionLeftMessage),
      checkPermission(permissions.viewEditProject.actionOnHold),
      checkPermission(permissions.viewEditProject.actionCompleted),
      checkPermission(permissions.viewEditProject.actionNeedReview),
      checkPermission(permissions.viewEditProject.actionCancelled),
      checkPermission(permissions.viewEditProject.actionButtonScheduler),
      checkPermission(permissions.viewEditProject.actionContractPending),
      checkPermission(permissions.viewEditProject.actionContractReviewed),
      checkPermission(permissions.project.addProject),
      checkPermission(permissions.viewEditProject.actionBustedJob),
      checkPermission(permissions.viewEditProject.actionReadyToSchedule),
      checkPermission(permissions.viewEditProject.actionSendToFloorsoft),
      checkPermission(permissions.viewEditProject.actionPullLineItems),
      checkPermission(permissions.viewEditProject.actionAcceptJob),
      checkPermission(permissions.viewEditProject.actionDeclineJob),
      checkPermission(permissions.viewEditProject.actionRepairOrder),
      checkPermission(permissions.viewEditProject.actionCarpetStagedView),
      checkPermission(permissions.viewEditProject.actionPadStagedView),
      checkPermission(permissions.viewEditProject.actionFollowUpAmCallView),
      checkPermission(permissions.viewEditProject.actionFollowUpPmCallView),
      checkPermission(permissions.viewEditProject.actionWelcomeLeftMessageView),
      checkPermission(
        permissions.viewEditProject.actionWelocomeCallLCompleteView
      ),
      checkPermission(permissions.viewEditProject.actionDispatchedView),
      checkPermission(permissions.viewEditProject.actionEtaConfirmedView),
      checkPermission(permissions.viewEditProject.actionsInProgressView),
      checkPermission(permissions.viewEditProject.actionsConfirmCompleteView),
      checkPermission(permissions.viewEditProject.actionQaCompleteView),
      checkPermission(permissions.viewEditProject.actionReundReviewedView),
      checkPermission(permissions.viewEditProject.actionSurveyResolvedView),
      checkPermission(permissions.viewEditProject.actionAcknowledgeChangeView),
      checkPermission(permissions.viewEditProject.actionAcknowledgeCancelView),
      checkPermission(permissions.viewEditProject.actionRequalify),
      checkPermission(permissions.viewEditProject.actionSyncStatus),
    ];
  }, [permissions?.viewEditProject]);

  const handleScroll = () => {
    setScrollPosition(window.scrollY);
  };

  const { sideDrawer } = useSelector(state => state.pageHeader);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const getStyles = clientDetails => {
    return {
      formStyle: lockProjectBar(clientDetails),
      globalStyle: lockGlobalBar(clientDetails),
    };
  };

  const { formStyle, globalStyle } = useMemo(
    () => getStyles(clientDetails),
    [clientDetails]
  );

  const projectBarDynamicWidth = useMemo(() => {
    return sideDrawer ? '96%' : 'calc(96% - 225px)';
  }, [sideDrawer]);

  useEffect(() => {
    const flag = projectLeadRequiredCondition(projectDetailsData, action);
    setProjectLeadRequired(flag);
  }, [
    projectDetailsData?.source_system_id,
    projectDetailsData?.year_built,
    projectDetailsData?.epa_lead_assessment_required,
    projectDetailsData?.epa_lead_remediation,
    projectDetailsData?.project_hd_epa_documents,
    action,
  ]);
  //
  const handleSetNeedSupportResponse = value => {
    if (value) {
      const flag = needSupportRequiredCondition(value);
      setNeedSupportRequired(flag);
    }
  };
  React.useEffect(() => {
    getNeedSupportData(
      formik?.values?.project_id,
      setLoading,
      handleSetNeedSupportResponse
    );
  }, [formik?.values?.projectId]);
  return (
    <>
      <GenericConfirmationDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        onConfirmDialog={onConfirmDialog}
      />
      <ActionNoteDialog
        bustedJobDialogVisible={bustedJobDialogVisible}
        setBustedJobDialogVisible={setBustedJobDialogVisible}
        projectInstaller={projectInstaller}
        projectId={projectId}
        notesFormik={notesFormik}
        quickActionReq={quickActionReq}
      />
      <Toast ref={toast} />
      <ConfirmDialog
        group="declarative"
        visible={openPopup}
        onHide={() => setOpenPopup(false)}
        message="This project cannot be completed until the EPA details have been updated."
        header="Confirmation"
        icon="pi pi-info-circle"
        accept={accept}
        reject={reject}
        acceptLabel="Ok"
        rejectLabel="Cancel"
        style={{ width: '50vw' }}
        breakpoints={{ '1100px': '75vw', '960px': '100vw' }}
      />
      <ConfirmPopup />
      <form className={classes.formWrapper}>
        <Grid container direction="column" spacing={2}>
          <Grid
            container
            item
            direction="row"
            justifyContent="space-between"
            spacing={2}
            style={
              formStyle
                ? {
                    position: 'fixed',
                    top: globalStyle
                      ? window.cxcScheduleAlert || window.marqueeText
                        ? '136px'
                        : '65px'
                      : scrollPosition > 50
                        ? '0px'
                        : window.cxcScheduleAlert || window.marqueeText
                          ? '137px'
                          : '72px',
                    width: projectBarDynamicWidth,

                    zIndex: 999,
                    backgroundColor: '#f6fbff',
                    marginLeft: '6px',
                  }
                : {
                    position: 'relative',
                    top: '0px',
                    zIndex: 999,
                  }
            }
          >
            <Grid xs={12} sm={12} lg={6} item>
              <PageHeader
                pageTitle={
                  action === 'add'
                    ? 'New Project'
                    : action === 'copy'
                      ? 'Copy Project'
                      : 'Project Details'
                }
                breadCrumbArray={projectDetailsBreadcrumb}
              />
            </Grid>
            <Grid
              xs={12}
              sm={12}
              lg={6}
              item
              classes={{ root: classes.selfAlignGrid }}
            >
              <Grid
                container
                item
                direction="row"
                className="flex md:justify-content-end vertical-align-middle py-2"
                spacing={1}
              >
                {checkPermission(
                  permissions?.viewEditProject?.actionScheduler
                ) && (
                  <Grid item>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() =>
                        window.open(
                          `/scheduler/${formik.values.project_number}`
                        )
                      }
                      disabled={loading || !formik.values.project_number}
                    >
                      Scheduler
                    </Button>
                  </Grid>
                )}
                {checkPermission(
                  permissions?.viewEditProject?.actionScheduler
                ) && (
                  <Grid item>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() =>
                        window.open(
                          `/scheduler-beta/${formik.values.project_number}`
                        )
                      }
                      disabled={loading || !formik.values.project_number}
                    >
                      Scheduler Beta
                    </Button>
                  </Grid>
                )}
                {action === 'view' ? (
                  <>
                    <Grid item>
                      {checkPermission(permissions?.project?.editProject) && (
                        <Button
                          color="primary"
                          variant="outlined"
                          disabled={loading}
                          onClick={() => history.push(editLink)}
                        >
                          Edit
                        </Button>
                      )}
                    </Grid>
                    <Grid item>
                      {checkPermission(permissions?.project?.addProject) && (
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            setCustomerDetails([]);
                            setStoreDetails({});
                            setValue('scheduleInfo');
                            history.push('/project/add');
                          }}
                          disabled={
                            !checkPermission(permissions?.project?.addProject)
                          }
                        >
                          Add New
                        </Button>
                      )}
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={() => {
                          if (action === 'edit') {
                            history.push(`/project/view/${projectId}`);
                            formik.resetForm();
                          } else {
                            history.goBack();
                          }
                        }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        color="primary"
                        variant="contained"
                        disabled={
                          (!formik?.dirty || !formik.isValid) &&
                          action !== 'copy'
                        }
                        onClick={event => {
                          if (formik?.values?.quoteId) {
                            confirmPopup({
                              target: event.currentTarget,
                              message:
                                'Saving this converted project from quote will copy the quote Items as well.',
                              icon: 'pi pi-info-circle',
                              acceptClassName: 'p-button-info',
                              accept: () => {
                                formik.handleSubmit();
                              },
                              reject: () => {
                                window.close();
                              },
                              rejectLabel: 'Cancel',
                              header: 'Confirmation',
                              pt: { content: { className: 'py-2' } },
                            });
                          } else {
                            formik.handleSubmit();
                          }
                        }}
                      >
                        Save
                      </Button>
                    </Grid>
                  </>
                )}

                {urlEvent.pre && (
                  <Grid item>
                    <PFButton
                      severity="primary"
                      label="Previous"
                      tooltipOptions={{ position: 'bottom' }}
                      onClick={() => {
                        history.push(urlEvent.pre);
                        window.scrollTo({
                          top: 0,
                          behavior: 'smooth',
                        });
                      }}
                    />
                  </Grid>
                )}

                {urlEvent.next && (
                  <Grid item>
                    <PFButton
                      severity="primary"
                      label="Next"
                      tooltipOptions={{ position: 'bottom' }}
                      onClick={() => {
                        history.push(urlEvent.next);
                        window.scrollTo({
                          top: 0,
                          behavior: 'smooth',
                        });
                      }}
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            style={
              formStyle
                ? {
                    position: 'relative',
                    top: globalStyle
                      ? window.cxcScheduleAlert || window.marqueeText
                        ? '100px'
                        : '65px'
                      : window.cxcScheduleAlert || window.marqueeText
                        ? '79px'
                        : '72px',
                  }
                : {
                    position: 'relative',
                    top: '10px',
                  }
            }
          >
            <Collapse in={expand} collapsedSize={60}>
              <Paper
                square
                className={`${classes.paperRoot} border-none project-info`}
                elevation={0}
              >
                <Grid container direction="column">
                  <Grid
                    container
                    item
                    direction="row"
                    justifyContent="space-between"
                    className={`${classes.containerHeader} project-header`}
                    xs={12}
                    wrap="nowrap"
                  >
                    <Grid
                      container
                      item
                      spacing={1}
                      // classes={{ root: classes.statusWrapper }}
                      xs={10}
                      md={11}
                    >
                      <Grid item>
                        <Typography variant="h3">Project Info</Typography>
                      </Grid>
                      {action !== 'add' && (
                        <Grid item>
                          <Button
                            variant="text"
                            classes={{ text: classes.statusButton }}
                          >
                            {formik?.values?.status?.status}
                          </Button>
                        </Grid>
                      )}

                      {projectLeadRequired && (
                        <Grid item>
                          <Button
                            variant="text"
                            classes={{ text: classes.leadBadge }}
                          >
                            Lead Required
                          </Button>
                        </Grid>
                      )}
                      {needSupportRequired?.result && (
                        <Grid item>
                          <Button
                            variant="text"
                            classes={{ text: classes.leadBadge }}
                          >
                            Need Support - {needSupportRequired?.recent_status}
                          </Button>
                        </Grid>
                      )}
                      {checkPermission(
                        permissions?.viewEditProject?.viewAllCostFields
                      ) &&
                        action !== 'add' &&
                        action !== 'copy' && (
                          <Grid item>
                            <Button
                              variant="text"
                              classes={{ text: classes.statusButton }}
                            >
                              {`$${
                                parseFloat(
                                  projectDetailsData?.total_sale_amount
                                ) == 0 ||
                                projectDetailsData?.total_sale_amount ===
                                  null ||
                                projectDetailsData?.total_sale_amount ===
                                  undefined ||
                                isNaN(
                                  parseFloat(
                                    projectDetailsData?.total_sale_amount
                                  )
                                )
                                  ? !isNaN(parseFloat(totalRevenue))
                                    ? totalRevenue
                                    : '0.00'
                                  : parseFloat(
                                      projectDetailsData?.total_sale_amount
                                    )?.toFixed(2)
                              }`}
                            </Button>
                          </Grid>
                        )}
                      {action !== 'add' &&
                        projectDetailsData?.project_additional_fields
                          ?.delivery === DELIVERY_STATUS && (
                          <Grid item>
                            <Button
                              variant="text"
                              classes={{ text: classes.statusButton }}
                            >
                              Delivery
                            </Button>
                          </Grid>
                        )}
                      {CLIENT_DETAILS.client_id == CLIENT_ID.BM_FLOORING &&
                      projectDetailsData.installChangeAuthDOcumentAvailable &&
                      isObject(
                        projectDetailsData.installChangeAuthDOcumentAvailable
                      ) ? (
                        <Grid item>
                          <Button
                            variant="text"
                            classes={{ text: classes.changeOrder }}
                          >
                            Change Order
                          </Button>
                        </Grid>
                      ) : null}
                    </Grid>
                    <Grid
                      container
                      item
                      classes={{ root: classes.statusWrapper }}
                      justifyContent="flex-end"
                      xs={2}
                      md={1}
                    >
                      {expand ? (
                        <Grid item>
                          <ExpandLess
                            classes={{ root: classes.menuHeaderIcon }}
                            onClick={() => setExpand(false)}
                          />
                        </Grid>
                      ) : (
                        <Grid item>
                          <ExpandMore
                            classes={{ root: classes.menuHeaderIcon }}
                            onClick={() => setExpand(true)}
                          />
                        </Grid>
                      )}
                      <Grid item>
                        {hasCtaViewProjectPermission && (
                          <MoreVertIcon
                            fontSize="medium"
                            classes={{ root: classes.menuVertIcon }}
                            onClick={handleClick}
                          />
                        )}

                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={Boolean(anchorEl)}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                          }}
                          PopoverClasses={{
                            paper: classes.menu,
                          }}
                          disableScrollLock
                          classes={{ list: classes.menuItem }}
                        >
                          {hasEditProjectPermission &&
                            formik?.values?.project_type?.project_type ===
                              'Work Order' && (
                              <MenuItem
                                disabled={
                                  loading ||
                                  !formik.values.project_number ||
                                  projectDetailsData?.status?.status ===
                                    'Work Order Needs a Detail'
                                }
                                onClick={() =>
                                  handleWorkOrderButtonClick(
                                    'work-order-needs-detail'
                                  )
                                }
                              >
                                Work Order Needs a Detail
                              </MenuItem>
                            )}

                          {hasActionLeftMessagePermission && (
                            <MenuItem
                              disabled={
                                !formik?.values?.project_number ||
                                projectDetailsData?.no_of_messages_sent === 3
                              }
                              onClick={() =>
                                handleStatusButtonClick('left-message')
                              }
                            >
                              {' '}
                              {(projectDetailsData?.no_of_messages_sent === 2 &&
                                'Left 3rd Message') ||
                                (projectDetailsData?.no_of_messages_sent ===
                                  1 &&
                                  'Left 2nd Message') ||
                                'Left Message'}
                            </MenuItem>
                          )}
                          {hasActionOnHoldPermission && (
                            <MenuItem
                              disabled={
                                !formik?.values?.project_number ||
                                projectDetailsData?.status?.status === 'On-Hold'
                              }
                              onClick={() => handleStatusButtonClick('on-hold')}
                            >
                              On Hold
                            </MenuItem>
                          )}
                          {hasActionCompletedPermission && (
                            <MenuItem
                              disabled={
                                !formik?.values?.project_number ||
                                projectDetailsData?.status?.status ===
                                  'Completed'
                              }
                              onClick={() =>
                                handleStatusButtonClick('completed')
                              }
                            >
                              Completed
                            </MenuItem>
                          )}

                          {hasActionNeedReviewPermission && (
                            <MenuItem
                              disabled={
                                !formik?.values?.project_number ||
                                projectDetailsData?.status?.status ===
                                  'Need Review'
                              }
                              onClick={() =>
                                handleStatusButtonClick('need-review')
                              }
                            >
                              Needs Review
                            </MenuItem>
                          )}
                          {hasActionCancelledPermission && (
                            <MenuItem
                              disabled={
                                !formik?.values?.project_number ||
                                projectDetailsData?.status?.status ===
                                  'Cancelled'
                              }
                              onClick={() =>
                                handleStatusButtonClick('cancelled')
                              }
                            >
                              Cancelled
                            </MenuItem>
                          )}

                          {hasActionButtonSchedulerPermission && (
                            <MenuItem
                              onClick={() =>
                                window.open(
                                  `/scheduler/${formik.values.project_number}`
                                )
                              }
                            >
                              Scheduler
                            </MenuItem>
                          )}
                          {hasActionContractPendingPermission && (
                            <MenuItem
                              disabled={
                                !formik?.values?.project_number ||
                                projectDetailsData?.status?.status ===
                                  'Contract Pending'
                              }
                              onClick={() =>
                                handleActionType('Contract_Pending')
                              }
                            >
                              Contract Pending
                            </MenuItem>
                          )}
                          {hasActionContractReviewedPermission && (
                            <MenuItem
                              disabled={
                                !formik?.values?.project_number ||
                                projectDetailsData?.status?.status ===
                                  'Waiting For Product'
                              }
                              onClick={() =>
                                handleActionType('Contract_Reviewed')
                              }
                            >
                              Contract Reviewed
                            </MenuItem>
                          )}
                          {hasAddProjectPermission && (
                            <MenuItem
                              onClick={() => {
                                setCustomerDetails([]);
                                setStoreDetails({});
                                setValue(' ');
                                history.push(`/project/copy/${projectId}`);
                              }}
                              disabled={action === 'add' || action === 'copy'}
                            >
                              Copy Project
                            </MenuItem>
                          )}
                          {hasActionBustedJobPermission && (
                            <MenuItem
                              onClick={() => {
                                setBustedJobDialogVisible(true);
                                setQuickActionReq(
                                  QUICK_ACTION_TYPE?.BUSTED_JOB
                                );
                              }}
                              disabled={
                                projectDetailsData?.status_id == '285'
                                  ? true
                                  : false
                              }
                            >
                              Busted Job
                            </MenuItem>
                          )}
                          {hasActionReadyToSchedulePermission && (
                            <MenuItem
                              onClick={() => {
                                readyToScheduleAction();
                                handleClose();
                                setQuickActionReq(
                                  QUICK_ACTION_TYPE?.READY_TO_SCHEDULE
                                );
                              }}
                              disabled={
                                projectDetailsData?.status_id == '6'
                                  ? true
                                  : false
                              }
                            >
                              Ready To Schedule
                            </MenuItem>
                          )}
                          {hasActionSendToFloorsoftPermission &&
                            !isNaN(CLIENT_DETAILS?.floorsoft_opted) &&
                            CLIENT_DETAILS?.floorsoft_opted > 0 && (
                              <MenuItem
                                onClick={() => {
                                  sendToFllorsoft();
                                  handleClose();
                                }}
                              >
                                Send to Floorsoft
                              </MenuItem>
                            )}
                          {hasActionPullLineItemsPermission &&
                            formik?.values?.source_system?.source_system_id ===
                              2 &&
                            formik?.values?.project_type?.project_type_id ===
                              2 && (
                              <MenuItem
                                onClick={() => {
                                  pullInstalltionLineItems();
                                  handleClose();
                                }}
                              >
                                Pull Line Items
                              </MenuItem>
                            )}
                          {hasActionAcceptJobPermission &&
                            formik?.values?.source_system?.source_system_id ===
                              SOURCE_SYSTEMS.LUMBER_LIQUIDATOR && (
                              <MenuItem
                                disabled={
                                  !formik?.values?.project_number ||
                                  formik?.values?.project_additional_fields
                                    ?.ll_job_accepted === 'Accepted'
                                }
                                onClick={() =>
                                  handleWorkOrderButtonClick('accepted')
                                }
                              >
                                Accept Job
                              </MenuItem>
                            )}
                          {hasActionDeclineJobPermission &&
                            formik?.values?.source_system?.source_system_id ===
                              SOURCE_SYSTEMS.LUMBER_LIQUIDATOR && (
                              <MenuItem
                                disabled={
                                  !formik?.values?.project_number ||
                                  formik?.values?.project_additional_fields
                                    ?.ll_job_accepted === 'Declined'
                                }
                                onClick={() =>
                                  handleWorkOrderButtonClick('declined')
                                }
                              >
                                Decline Job
                              </MenuItem>
                            )}
                          {hasActionRepairOrderPermission &&
                            formik?.values?.source_system?.source_system_id ===
                              SOURCE_SYSTEMS.LUMBER_LIQUIDATOR &&
                            formik?.values?.project_type?.project_type_id ===
                              PROJECT_TYPE.MEASUREMENT && (
                              <MenuItem
                                onClick={handleRepairOrderClick}
                                disabled={action === 'add' || action === 'copy'}
                              >
                                Repair Order
                              </MenuItem>
                            )}
                          {hasActionCarpetStagedViewPermission && (
                            <MenuItem
                              onClick={() => {
                                handleActionType('carpet_staged');
                              }}
                              disabled={
                                !(action === 'view' || action === 'edit')
                              }
                            >
                              Carpet Staged
                            </MenuItem>
                          )}
                          {hasActionPadStagedViewPermission && (
                            <MenuItem
                              onClick={() => {
                                handleActionType('pad_staged');
                              }}
                              disabled={
                                !(action === 'view' || action === 'edit')
                              }
                            >
                              Pad Staged
                            </MenuItem>
                          )}
                          {hasActionFollowUpAmCallViewPermission && (
                            <MenuItem
                              onClick={() => {
                                handleActionType('follow_up_am_call');
                              }}
                              disabled={
                                !(action === 'view' || action === 'edit')
                              }
                            >
                              Follow-up AM Call
                            </MenuItem>
                          )}
                          {hasActionFollowUpPmCallViewPermission && (
                            <MenuItem
                              onClick={() => {
                                handleActionType('follow_up_pm_call');
                              }}
                              disabled={
                                !(action === 'view' || action === 'edit')
                              }
                            >
                              Follow-up PM Call
                            </MenuItem>
                          )}
                          {hasActionWelcomeLeftMessageViewPermission && (
                            <MenuItem
                              onClick={() => {
                                handleActionType('welcome_left_message');
                              }}
                              disabled={
                                !(action === 'view' || action === 'edit')
                              }
                            >
                              Welcome - Left Message
                            </MenuItem>
                          )}
                          {hasActionWelcomeCallCompleteViewPermission && (
                            <MenuItem
                              onClick={() => {
                                handleActionType('welcome_call_complete');
                              }}
                              disabled={
                                !(action === 'view' || action === 'edit')
                              }
                            >
                              Welcome - Call Complete
                            </MenuItem>
                          )}
                          {hasActionDispatchedViewPermission && (
                            <MenuItem
                              onClick={() => {
                                handleActionType('dispatched');
                              }}
                              disabled={
                                !(action === 'view' || action === 'edit')
                              }
                            >
                              Dispatched
                            </MenuItem>
                          )}
                          {hasActionEtaConfirmedViewPermission && (
                            <MenuItem
                              onClick={() => {
                                handleActionType('eta_confirmed');
                              }}
                              disabled={
                                !(action === 'view' || action === 'edit')
                              }
                            >
                              ETA Confirmed
                            </MenuItem>
                          )}
                          {hasActionsInProgressViewPermission && (
                            <MenuItem
                              onClick={() => {
                                handleActionType('in_progress');
                              }}
                              disabled={
                                !(action === 'view' || action === 'edit')
                              }
                            >
                              In Progress
                            </MenuItem>
                          )}
                          {hasActionsConfirmCompleteViewPermission && (
                            <MenuItem
                              onClick={() => {
                                handleActionType('confirm_complete');
                              }}
                              disabled={
                                !(action === 'view' || action === 'edit')
                              }
                            >
                              Customer Confirmed
                            </MenuItem>
                          )}
                          {hasActionQaCompleteViewPermission && (
                            <MenuItem
                              onClick={() => {
                                handleActionType('qa_complete');
                              }}
                              disabled={
                                !(action === 'view' || action === 'edit')
                              }
                            >
                              QA Complete
                            </MenuItem>
                          )}
                          {hasActionRefundReviewedViewPermission && (
                            <MenuItem
                              onClick={() => {
                                handleActionType('refund_reviewed');
                              }}
                              disabled={
                                !(action === 'view' || action === 'edit')
                              }
                            >
                              Refund Reviewed
                            </MenuItem>
                          )}
                          {hasActionSurveyResolvedViewPermission && (
                            <MenuItem
                              onClick={() => {
                                handleActionType('survey_status');
                              }}
                              disabled={
                                !(action === 'view' || action === 'edit')
                              }
                            >
                              Survey Resolved
                            </MenuItem>
                          )}

                          {projectDetailsData?.source_system
                            ?.source_system_id === SOURCE_SYSTEMS.HOME_DEPOT &&
                            hasActionAcknowledgeChangeViewPermission && (
                              <MenuItem
                                onClick={() => {
                                  handleActionType('acknowledge_change');
                                }}
                                disabled={
                                  !(action === 'view' || action === 'edit')
                                }
                              >
                                Acknowledge Change
                              </MenuItem>
                            )}
                          {projectDetailsData?.source_system
                            ?.source_system_id === SOURCE_SYSTEMS.HOME_DEPOT &&
                            hasActionAcknowledgeCancelViewPermission && (
                              <MenuItem
                                onClick={() => {
                                  handleActionType('acknowledge_cancel');
                                }}
                                disabled={
                                  !(action === 'view' || action === 'edit')
                                }
                              >
                                Acknowledge Cancel
                              </MenuItem>
                            )}
                          {hasActionRequalifyViewPermission && (
                            <MenuItem
                              disabled={
                                !formik?.values?.project_number ||
                                projectDetailsData?.status?.status ===
                                  'Requalify'
                              }
                              onClick={() => handleActionType('Requalify')}
                            >
                              Requalify
                            </MenuItem>
                          )}
                          {projectDetailsData?.source_system
                            ?.source_system_id === SOURCE_SYSTEMS.HOME_DEPOT &&
                            hasActionSyncStatusPermission && (
                              <MenuItem
                                onClick={() => {
                                  handleActionType('sync_status');
                                }}
                                disabled={
                                  !(action === 'view' || action === 'edit')
                                }
                              >
                                Sync Status
                              </MenuItem>
                            )}
                        </Menu>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid item className={`${classes.containerContent} bg-white`}>
                    <ProjectInfo
                      setLoading={setLoading}
                      loading={loading}
                      formik={formik}
                      action={action}
                      customerDetails={customerDetails}
                      setCustomerDetails={setCustomerDetails}
                      storeDetails={storeDetails}
                      setStoreDetails={setStoreDetails}
                      getConfigurationEntities={getConfigurationEntities}
                      opportunityInfo={opportunityInfo}
                      setOpportunityInfo={setOpportunityInfo}
                      allUserList={allUserList}
                      setAllUserList={setAllUserList}
                      isExternalSource={isExternalSource || 0}
                      projectDetailsData={projectDetailsData}
                      setOpenPopup={setOpenPopup}
                      setEPALeadReasontoUpdate={setEPALeadReasontoUpdate}
                    />
                  </Grid>
                </Grid>
              </Paper>
            </Collapse>
          </Grid>
          <Grid
            container
            item
            style={
              formStyle
                ? {
                    position: 'relative',
                    top: globalStyle
                      ? window.cxcScheduleAlert || window.marqueeText
                        ? '100px'
                        : '65px'
                      : window.cxcScheduleAlert || window.marqueeText
                        ? '79px'
                        : '72px',
                  }
                : {
                    position: 'relative',
                    top: '10px',
                  }
            }
          >
            {action !== 'copy' && (
              <Paper
                square
                className={`${classes.tabsPaper} border-none shadow-none`}
              >
                <TabContext value={value}>
                  <Tabs
                    value={value}
                    onChange={handleTabChange}
                    indicatorColor="primary"
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                    classes={{
                      root: classes.tabRoot,
                      scrollButtons: classes.scrollButtons,
                      indicator: classes.indicator,
                    }}
                    TabIndicatorProps={{ children: <span /> }}
                  >
                    {projectTabs
                      ?.filter(tab => {
                        return tab.visible && (tab?.hide ? !tab.hide : true);
                      })
                      .map((tab, index) => {
                        return (
                          <Tab
                            key={index}
                            label={tab.name}
                            {...updateTabId(index)}
                            value={tab.value}
                            className={
                              tab.className
                                ? tab.className
                                : classes.tabTextColor
                            }
                          />
                        );
                      })}
                  </Tabs>
                  {projectTabs
                    ?.filter(tab => {
                      return tab.visible && (tab?.hide ? !tab.hide : true);
                    })
                    .map((tab, index) => {
                      return (
                        <TabPanel
                          value={tab.value}
                          key={index}
                          className={classes.tabPanel}
                        >
                          {tab.component}
                        </TabPanel>
                      );
                    })}
                </TabContext>
              </Paper>
            )}
          </Grid>
        </Grid>
      </form>
      {action !== 'add' && action !== 'copy' && (
        <Footer
          createdBy={
            projectDetailsData?.created_user?.first_name ||
            projectDetailsData?.created_user?.last_name
              ? `${projectDetailsData?.created_user?.first_name || null}  ${
                  projectDetailsData?.created_user?.last_name || null
                }`
              : null
          }
          createdAt={projectDetailsData?.created_at || null}
          modifiedBy={
            projectDetailsData?.modified_user?.first_name ||
            projectDetailsData?.modified_user?.last_name
              ? `${projectDetailsData?.modified_user?.first_name || null}  ${
                  projectDetailsData?.modified_user?.last_name || null
                }`
              : null
          }
          modifiedAt={projectDetailsData?.modified_at || null}
          lockProjectBar={lockProjectBar}
          width={width}
        />
      )}

      {/* Add task-------------------------------------------------- */}
      <Sidebar
        baseZIndex="1000"
        visible={visibleSidebar}
        onHide={() => {
          setIsAddMode(true);
          setRefereshList(!refereshList);
          setVisibleSidebar(false);
        }}
        fullScreen
        className="task-management p-0"
        icons={customHeader}
        pt={{
          header: {
            className: 'justify-content-between bg-primary',
          },
          icons: {
            className: 'w-12',
          },
        }}
      >
        <TaskManagement
          typeOfProject={typeOfProject}
          taskProjectId={taskProjectId}
        />
      </Sidebar>
      {checkProjectPage ? (
        <PButton
          onClick={() => setVisibleSidebar(true)}
          icon="pi pi-book text-2xl"
          rounded
          severity="primary"
          className="p-1 fixed"
          style={{
            zIndex: '9999',
            bottom: '30px',
            left: '30px',
          }}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default AddViewEditProjectDetails;
