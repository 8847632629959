import { Dialog } from 'primereact/dialog';
import { InputSwitch } from 'primereact/inputswitch';
import { Divider } from 'primereact/divider';

import PFButton from '../../../shared/PFPrime/PFButton';
import PFMultiSelect from '../../../shared/PFPrime/PFMultiSelect';
import PFInputText from '../../../shared/PFPrime/PFInputText';

const AddCustomFilter = ({
  customFilterDialogVisible,
  setCustomFilterDialogVisible,
  formik,
  suggestionsProps,
  optionsProps,
  selectedLabel,
  customFilterFormik,
  loading,
  dropdownError,
  setDropdownError,
  assignFormikValues,
}) => {
  return (
    <>
      <Dialog
        header="Custom Filters"
        visible={customFilterDialogVisible}
        className="w-11 xl:w-8"
        onHide={() => {
          setCustomFilterDialogVisible(false);
          customFilterFormik.handleReset();
          setDropdownError(false);
        }}
        draggable={false}
      >
        <div className="card w-12">
          <form
            onSubmit={formik.handleSubmit}
            className="flex flex-column gap-2 surface-50 px-2"
          >
            <div className="grid mt-2">
              <div className="col-12 flex flex-wrap p-0">
                <div className="col-12 md:col-4">
                  <span className="p-float-label">
                    <PFMultiSelect
                      value={formik.values.customerId}
                      onChange={e => {
                        formik.setFieldValue('customerId', e.value);
                      }}
                      options={
                        Array.isArray(optionsProps?.customerOptions)
                          ? optionsProps?.customerOptions?.map(option => ({
                              label: `${option?.first_name} ${
                                option?.last_name
                              }-${
                                option.primary_phone ? option.primary_phone : ''
                              }`,
                              value: option.customer_id,
                              customer_id: option.customer_id,
                            }))
                          : []
                      }
                      optionValue="customer_id"
                      filter={true}
                      filterPlaceholder="Min 3 chars to search"
                      onFilter={e => {
                        if (e.filter.length > 2) {
                          suggestionsProps?.suggestCustomerOptions(
                            e.filter,
                            optionsProps?.setCustomerOptions
                          );
                        }
                      }}
                      maxSelectedLabels={0}
                      className="w-full text-sm"
                      selectedItemsLabel={selectedLabel(
                        formik?.values?.customerId || [],
                        'Item'
                      )}
                      showSelectAll={false}
                      placeholder="Select Client Name"
                    />
                    <label htmlFor="client_name">Select Client Name</label>
                  </span>
                </div>
                <div className="col-12 md:col-4">
                  <span className="p-float-label">
                    <PFMultiSelect
                      value={formik.values.storeNumber}
                      options={
                        Array.isArray(optionsProps?.storeOptions)
                          ? optionsProps?.storeOptions?.map(store => ({
                              label: `${store.store_number} - ${store.store_name}`,
                              value: store.store_id,
                              store_number: store.store_number,
                            }))
                          : []
                      }
                      onChange={e => {
                        formik.setFieldValue('storeNumber', e.value);
                      }}
                      optionValue="store_number"
                      filter={true}
                      filterPlaceholder="Min 3 chars to search"
                      onFilter={e => {
                        if (e.filter.length > 2) {
                          suggestionsProps?.suggestStore(
                            e,
                            optionsProps?.setStoreOptions
                          );
                        }
                      }}
                      maxSelectedLabels={0}
                      className="w-full text-sm"
                      selectedItemsLabel={selectedLabel(
                        formik?.values?.storeNumber || [],
                        'Item'
                      )}
                      showSelectAll={false}
                    />
                    <label htmlFor="store_name">Select Store</label>
                  </span>
                </div>
                <div className="col-12 md:col-4">
                  <span className="p-float-label">
                    <PFMultiSelect
                      value={formik.values.projectCategoryId}
                      options={optionsProps?.categoryOptions || []}
                      onChange={e =>
                        formik.setFieldValue('projectCategoryId', e.value)
                      }
                      optionLabel="category"
                      optionValue="project_category_id"
                      maxSelectedLabels={0}
                      filter
                      className="w-full text-sm"
                      onBlur={formik.handleBlur}
                      showSelectAll={false}
                      selectedItemsLabel={selectedLabel(
                        formik?.values?.projectCategoryId || [],
                        'Item'
                      )}
                    />
                    <label htmlFor="category">Select Category</label>
                  </span>
                </div>
              </div>
              <div className="col-12 flex flex-wrap p-0">
                <div className="col-12 md:col-4">
                  <span className="p-float-label">
                    <PFMultiSelect
                      value={formik.values.projectTypeId}
                      options={optionsProps?.projectTypeOptions || []}
                      onChange={e =>
                        formik.setFieldValue('projectTypeId', e.value)
                      }
                      placeholder="Project Type"
                      optionLabel="project_type"
                      optionValue="project_type_id"
                      filter
                      maxSelectedLabels={0}
                      className="w-full text-sm"
                      selectedItemsLabel={selectedLabel(
                        formik?.values?.projectTypeId || [],
                        'Item'
                      )}
                      showSelectAll={false}
                    />
                    <label htmlFor="projectTypeId">Select Project Type</label>
                  </span>
                </div>
                <div className="col-12 md:col-4">
                  <span className="p-float-label">
                    <PFMultiSelect
                      value={formik.values.statusId}
                      options={optionsProps?.projectStatusOptions || []}
                      onChange={e => formik.setFieldValue('statusId', e.value)}
                      className="w-full text-sm"
                      placeholder="Status"
                      optionLabel="status"
                      optionValue="status_id"
                      filter
                      maxSelectedLabels={0}
                      selectedItemsLabel={selectedLabel(
                        formik?.values?.statusId || [],
                        'Item'
                      )}
                      showSelectAll={false}
                    />
                    <label htmlFor="statusId">Select Status</label>
                  </span>
                </div>
                <div className="col-12 md:col-4">
                  <span className="p-float-label">
                    <PFMultiSelect
                      value={formik.values.imsStatus}
                      options={optionsProps?.sourceStatusOptions || []}
                      onChange={e => formik.setFieldValue('imsStatus', e.value)}
                      className="w-full text-sm"
                      optionLabel="status"
                      optionValue="status_id"
                      filter
                      maxSelectedLabels={0}
                      selectedItemsLabel={selectedLabel(
                        formik?.values?.imsStatus || [],
                        'Item'
                      )}
                      showSelectAll={false}
                    />
                    <label htmlFor="imsStatus">Select Source Status</label>
                  </span>
                </div>
              </div>
              <div className="col-12 flex flex-wrap p-0">
                <div className="col-12 md:col-4">
                  <span className="p-float-label">
                    <PFMultiSelect
                      value={formik.values.districtId}
                      options={optionsProps?.districtOptions || []}
                      onChange={e =>
                        formik.setFieldValue('districtId', e.value)
                      }
                      onFilter={e => {
                        suggestionsProps?.suggestDistrict(
                          e,
                          optionsProps?.setDistrictOptions
                        );
                      }}
                      className="w-full text-sm"
                      optionLabel="district"
                      optionValue="district"
                      filter
                      maxSelectedLabels={0}
                      selectedItemsLabel={selectedLabel(
                        formik?.values?.districtId || [],
                        'Item'
                      )}
                      showSelectAll={false}
                    />
                    <label htmlFor="district">Select District</label>
                  </span>
                </div>
                <div className="col-12 md:col-4">
                  <span className="p-float-label">
                    <PFMultiSelect
                      id="workroom_id"
                      value={formik?.values?.workroomId}
                      options={optionsProps?.workRoomOptions || []}
                      onChange={e =>
                        formik.setFieldValue('workroomId', e.value)
                      }
                      onFilter={e => {
                        suggestionsProps?.suggestWorkRoom(
                          e,
                          optionsProps?.setWorkroomOptions
                        );
                      }}
                      optionLabel="label"
                      optionValue="type_id"
                      placeholder="Search Type"
                      maxSelectedLabels={0}
                      filter
                      className="w-full text-sm"
                      selectedItemsLabel={selectedLabel(
                        formik?.values?.workroomId || [],
                        'Item'
                      )}
                      showSelectAll={false}
                    />

                    <label htmlFor="workroomId">Select Workroom</label>
                  </span>
                </div>
                <div className="col-12 md:col-4 flex justify-content-end">
                  <PFButton
                    label="Reset"
                    className="text-sm mr-3"
                    outlined
                    onClick={() => {
                      formik.handleReset();
                      setDropdownError(false);
                    }}
                    size="small"
                  />
                  <PFButton
                    label="Apply Filters"
                    type="submit"
                    onClick={() => {
                      setDropdownError(false);
                    }}
                    size="small"
                  />
                </div>
              </div>
            </div>
          </form>
          {dropdownError && (
            <small className="p-error text-xs pl-2">
              Please select a dropdown value.
            </small>
          )}
          <Divider />
          <form
            onSubmit={customFilterFormik.handleSubmit}
            className="flex flex-column gap-2 pt-0 px-2"
          >
            <div className="grid mt-2 p-0">
              <div className="col-12 flex flex-wrap p-0">
                <div className="col-12 md:col-4">
                  <span className="p-float-label ">
                    <PFInputText
                      id="filter_name"
                      maxLength={200}
                      value={customFilterFormik?.values?.filter_name}
                      onChange={e => {
                        customFilterFormik.setFieldValue(
                          'filter_name',
                          e?.target?.value
                        );
                        setDropdownError(false);
                      }}
                      className={`w-full text-sm ${
                        customFilterFormik.touched?.filter_name &&
                        customFilterFormik.errors?.filter_name
                          ? 'p-invalid'
                          : ''
                      }`}
                    ></PFInputText>
                    <label htmlFor="filter_name" className="text-sm">
                      Name
                    </label>
                  </span>
                </div>
                <div className="col-12 md:col-4">
                  <div className="col-12 flex align-items-center gap-2">
                    <InputSwitch
                      checked={customFilterFormik.values.isActive}
                      onChange={e => {
                        customFilterFormik.setFieldValue('isActive', e.value);
                      }}
                      className="text-xs"
                    />
                    <label className=" ml-1 text-base">
                      {customFilterFormik.values.isActive
                        ? ` Active`
                        : `Inactive`}{' '}
                    </label>
                  </div>
                </div>
                <div className="col-12 md:col-4 flex justify-content-end">
                  <PFButton
                    label="Cancel"
                    className="text-sm mr-2"
                    outlined
                    onClick={() => {
                      customFilterFormik.handleReset();
                      setDropdownError(false);
                      setCustomFilterDialogVisible(false);
                    }}
                    size="small"
                  />

                  <PFButton
                    label="Save"
                    type="submit"
                    onClick={() => {
                      assignFormikValues();
                    }}
                    disabled={!customFilterFormik.isValid || loading}
                    icon={loading ? 'pi pi-spin pi-spinner' : ''}
                    pt={{
                      label: {
                        className: 'flex-none pr-1 m-0',
                      },
                    }}
                    className="flex flex-row-reverse px-5 ml-3 text-sm"
                    size="small"
                  />
                </div>
              </div>
            </div>
          </form>
        </div>
      </Dialog>
    </>
  );
};
export default AddCustomFilter;
