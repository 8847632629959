// **** React Imports ****
import React, { useEffect, useRef } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Toast } from 'primereact/toast';
// **** External Utilities ****
import {
  Grid,
  IconButton,
  CircularProgress,
  Icon,
  Typography,
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import { Button } from 'primereact/button';
import {
  Visibility as VisibilityIcon,
  Edit as EditIcon,
  FileCopy as CopyIcon,
  PostAdd as AddFieldsIcon,
  Delete as DeleteIcon,
} from '@material-ui/icons';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
import Autocomplete from '@material-ui/lab/Autocomplete';

// **** Custom Components ****
import { Menu } from 'primereact/menu';

import PageHeader from '../../shared/PageHeader/PageHeader';
import GenericDataTable from '../../shared/GenericDataTable/GenericDataTable';
import GenericDialog from '../../shared/Dialog/GenericDialog';
import GenericConfirmationDialog from '../../shared/GenericConfirmationDialog/GenericConfirmationDialog';
import { Alert } from '../../shared/Alerts/Alert';

// **** Services *****
import { useAlerts } from '../../shared/Alerts/alertsService';

// **** Styles/Images/Icons ****
import { checkPermission } from '../../../utils/Helpers';
import permissions from '../../../config/permissions';
import { getStates } from '../../ProjectManagement/Clients/Clients.service';
import {
  HD_DOC_NUMBERS_FOR_DOCUMENTS,
  isLeadRequiredDropdown,
  LL_DOC_TYPE_FOR_DOCUMENTS,
  SOURCE_SYSTEMS,
} from '../../../constants';
import PFTableLoader from '../../shared/Loader/PFTableLoader';
import ApiService from '../../../services/api.service';

import { useStyles } from './Templates.styles';
import {
  getTemplatesList,
  getTemplateTypes,
  getSourceOptions,
  getCategoriesOptions,
  createTemplate,
  copyTemplate,
  updateTemplateDetails,
  deleteDocument,
  applyInstallerVisibilityTemplates,
} from './Templates.service';
import { mapYesNoDropdownValue } from './helper/helper';
function handleRowHover(event, row, rowIndex) {
  let control = document.getElementById(rowIndex);
  control.style.display = 'block';
}

function handleRowHoverLeave(event, row, rowIndex) {
  let control = document.getElementById(rowIndex);
  if (control?.style?.display) control.style.display = 'none';
}

const templatesBreadcrumb = [
  {
    text: 'Admin',
  },
  {
    text: 'Document Center',
  },
];

const Templates = () => {
  const optionsOverlayPanelRef = useRef(null);
  const [templatesListData, setTemplatesListData] = React.useState([]);
  const [optionsToDisplay, setOptionsToDisplay] = React.useState([]);
  const [sourceOptions, setSourceOptions] = React.useState([]);
  const [TemplateTypesOptions, setTemplateTypesOptions] = React.useState([]);
  const [categoriesOptions, setCategoriesOptions] = React.useState([]);
  const [templatesCount, setTemplatesCount] = React.useState();
  const [fileName, setFileName] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [formAction, setFormAction] = React.useState('add');
  const [uploadNewTemplate, setUploadNewTemplate] = React.useState(false);
  const [loadingPosition] = React.useState(false);
  const [copyOfTemplateData, setCopyOfTemplateData] = React.useState();
  const [rowIndex, setRowIndex] = React.useState();
  const [templateId, setTemplateId] = React.useState();
  const { alert, setAlert, clearAlert } = useAlerts();
  const [dialogSettings, setDialogSettings] = React.useState({
    title: 'Create New Template',
    button1Text: '',
    button2Text: 'Create',
    showButton1: true,
    showButton2: true,
  });
  const [searchQuery, setSearchQuery] = React.useState({
    limit: 10,
    offset: 0,
  });
  const history = useHistory();
  const classes = useStyles();
  const [isOpen, setIsOpen] = React.useState(false);
  const [reloadForm, setReloadForm] = React.useState(false);
  const [selectedFilters, setSelectedFilters] = React.useState();
  const [confirmDialog, setConfirmDialog] = React.useState({
    header: '',
    title: '',
    subtitle: '',
    isOpen: false,
  });
  const [confirmDeleteDialog, setConfirmDeleteDialog] = React.useState({
    header: '',
    title: '',
    subtitle: '',
    isOpen: false,
  });
  const [states, setStates] = React.useState([]);
  const [reloadList, setReloadList] = React.useState(false);
  const [checkBoxDisabledStates, setCheckBoxDisabledStates] = React.useState(
    []
  );
  const [isChecked, setIsChecked] = React.useState([]);
  const [hideAutoCreateCheckbox, setHideAutoCreateCheckbox] =
    React.useState(false);
  const [tableOrder, setTableOrder] = React.useState('asc');
  const toast = useRef(null);

  let intervalVal = '';
  const handleSearch = searchInputVal => {
    const searchString = searchInputVal?.trim();

    /** Timeout will help to let multiple characters in the TextInput,* and API call would be optimized to get Values for Input of few chars at once,* rather than for every single character*/
    try {
      clearTimeout(intervalVal);
    } catch (err) {
      console.log(err);
    }
    intervalVal = setTimeout(() => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        offset: 0,
        search_query: searchString,
      }));
    }, 500);
  };

  React.useEffect(() => {
    getStates(setStates, setLoading);
  }, []);

  const fetchTemplates = () => {
    getTemplatesList(
      searchQuery,
      selectedFilters,
      setLoading,
      setTemplatesListData,
      setTemplatesCount
    );
  };

  React.useEffect(() => {
    fetchTemplates();
  }, [searchQuery, selectedFilters, reloadForm]);

  React.useEffect(() => {
    getSourceOptions(setLoading, setSourceOptions);
    getTemplateTypes(setLoading, setTemplateTypesOptions);
    getCategoriesOptions(setLoading, setCategoriesOptions);
    clearAlert();
  }, []);

  const getSortKey = changedColumn => {
    let col;
    switch (changedColumn) {
      case 'source_system_ids':
        col = 'source_system_id';
        break;
      case 'type':
        col = 'project_type';
        break;
      case 'state':
        col = 'state_code';
        break;
      case 'is_customer_visible':
        col = 'is_customer_visible';
        break;
      case 'is_signature_required':
        col = 'is_signature_required';
        break;
      case 'auto_create_project_document':
        col = 'auto_create_project_document';
        break;
      default:
        col = changedColumn;
        break;
    }
    return col;
  };

  const handleFilterSubmit = applyNewFilters => {
    let appliedFiltersList = applyNewFilters();

    let selectedFilterObject = {};
    appliedFiltersList.map((data, index) => {
      if (data?.length) {
        let columnKey = dataTableColumns[index]?.name;
        let selectedFilterOptionsKey = [];
        switch (columnKey) {
          case 'source_system_ids':
            selectedFilterOptionsKey = data.map(val => val?.source_system_id);
            break;

          default:
            selectedFilterOptionsKey = [];
        }
        selectedFilterObject = {
          ...selectedFilterObject,
          [columnKey]: selectedFilterOptionsKey,
        };
      }
    });
    setSelectedFilters(selectedFilterObject);
  };

  const signatureRequiredChangeHandler = async (
    templateId,
    is_signature_required
  ) => {
    try {
      toast.current.show({
        severity: 'info',
        summary: 'Info',
        detail: 'Updating signature required status',
      });
      await ApiService.post(
        '/documents/templates/update-ready-signature-status',
        {
          templateId,
          is_signature_required: is_signature_required ? 1 : 0,
        }
      );
      toast.current.show({
        severity: 'success',
        summary: 'Success',
        detail: 'Signature required status updated successfully',
      });
      fetchTemplates();
    } catch (error) {
      console.error(error);
      toast.current.show({
        severity: 'error',
        summary: 'Error',
        detail: 'failed to update signature required status',
      });
    }
  };
  const showToCustomerHandler = async ({ severity, summary, detail }) => {
    try {
      toast.current.show({
        severity: severity,
        summary: summary,
        detail: detail,
      });
    } catch (error) {
      console.error(error);
    }
  };

  //Get States Name
  const getStateName = stateCode =>
    states?.find(state => state?.state_code === stateCode)?.state_name;
  let dataArray =
    (templatesListData.length > 0 &&
      templatesListData?.map((val, index) => {
        return {
          template_id: val?.template_id,
          source_system_ids: val?.source_system?.logo_url ? (
            <Icon fontSize="large" component="span">
              <img
                width="40"
                height="30"
                alt="ProjectForce"
                src={val?.source_system.logo_url}
              />
            </Icon>
          ) : (
            <Box className={classes.sourceName}>
              <Typography variant="subtitle2">
                {val?.source_system?.source_name || ''}
              </Typography>
            </Box>
          ),
          type: val?.type?.project_type || 'All',
          category: val?.category?.category || 'All',
          state: val?.state_code ? getStateName(val?.state_code) : 'All',
          created_at: val.created_at
            ? moment(val?.created_at).format('MM-DD-YYYY hh:mm A')
            : null,
          template_name: val?.template_name,
          document_url: () => (
            <a
              href={val?.template_aws_url}
              target="_blank"
              rel="noreferrer"
              className="text-primary"
            >
              {val?.template_file}
            </a>
          ),
          is_custom: val?.is_custom ? true : false,
          source_system_id: val?.source_system_id,
          project_type_id: val?.project_type_id,
          project_category_id: val?.project_category_id,
          template_file: val?.template_file,
          template_aws_url: val?.template_aws_url,
          hd_doc_cd_number: val?.hd_doc_cd_number,
          state_code: val?.state_code,
          is_lead_required:
            isLeadRequiredDropdown.find(
              item => item.id === val?.is_lead_required
            )?.value || 'All',
          ll_document_type: val?.ll_document_type,
          is_customer_visible: val?.is_customer_visible ? true : false,
          auto_create_project_document: val?.auto_create_project_document
            ? true
            : false,
          is_signature_required: val?.is_signature_required ? true : false,
        };
      })) ||
    [];
  useEffect(() => {
    setIsChecked(
      dataArray.map(item => ({
        is_customer_visible: !!item.is_customer_visible,
        auto_create_project_document: !!item.auto_create_project_document,
        id: item.template_id,
      }))
    );
  }, [dataArray?.length]);

  const handleCheckbox = async (event, value, dataIndex, queryParam) => {
    showToCustomerHandler({
      severity: 'info',
      summary: 'Info',
      detail: 'Updating template status.',
    });
    const updatedStates = [...checkBoxDisabledStates];
    updatedStates[dataIndex] = true;
    setCheckBoxDisabledStates(updatedStates);
    setReloadList(false);
    const payload = [
      {
        templateId: dataArray[dataIndex]?.template_id,
      },
    ];
    if (queryParam === 'customerProjectTemplates') {
      payload[0]['isCustomerProjectTemplateVisible'] = event.target.checked;
    } else {
      payload[0]['isAutoCreateProjectDocument'] = event.target.checked;
    }
    await applyInstallerVisibilityTemplates(
      queryParam,
      payload,
      setLoading,
      setReloadList
    );
    await getTemplatesList(
      searchQuery,
      selectedFilters,
      setLoading,
      setTemplatesListData,
      setTemplatesCount
    );
    showToCustomerHandler({
      severity: 'success',
      summary: 'Success',
      detail: 'Template status updated successfully',
    });
    const updatedStatusDisable = [...checkBoxDisabledStates];
    updatedStatusDisable[dataIndex] = false;
    setCheckBoxDisabledStates(updatedStatusDisable);
  };

  const optionPopInteractionHandler = (rowData, dataIndex, e) => {
    const viewIconButton = (
      <Link
        to={`/admin/documentcenter/view/${dataArray && dataArray[dataIndex]?.template_id}`}
      >
        <div className="w-6rem">
          <FormControlLabel
            control={
              <IconButton classes={{ root: classes.actionIcons }}>
                <VisibilityIcon />
              </IconButton>
            }
            label="View"
          />
        </div>
      </Link>
    );

    const editIconButton = (
      <div
        className="w-6rem"
        onClick={() => {
          editTemplateDetails(
            'edit',
            dataIndex,
            dataArray && dataArray[dataIndex]
          );
        }}
      >
        <FormControlLabel
          control={
            <IconButton classes={{ root: classes.actionIcons }}>
              <EditIcon />
            </IconButton>
          }
          label="Edit"
        />
      </div>
    );

    const addFieldsIconButton = (
      <Link
        to={`/admin/documentcenter/edit/${dataArray && dataArray[dataIndex]?.template_id}`}
      >
        <FormControlLabel
          control={
            <IconButton classes={{ root: classes.actionIcons }}>
              <AddFieldsIcon />
            </IconButton>
          }
          label="Add Fields"
        />
      </Link>
    );

    const copyIconButton = (
      <div
        className="w-6rem"
        onClick={() => confirmClone(dataArray[dataIndex])}
      >
        <FormControlLabel
          control={
            <IconButton classes={{ root: classes.actionIcons }}>
              <CopyIcon />
            </IconButton>
          }
          label="Copy"
        />
      </div>
    );

    const deleteIconButton = (
      <div
        className="w-6rem"
        onClick={() => confirmDelete(dataArray[dataIndex])}
      >
        <FormControlLabel
          control={
            <IconButton classes={{ root: classes.actionIcons }}>
              <DeleteIcon />
            </IconButton>
          }
          label="Delete"
        />
      </div>
    );

    const items = [
      {
        label: (
          <span>
            {checkPermission(permissions?.documentCenter?.menu) && (
              <>{viewIconButton}</>
            )}
          </span>
        ),
      },
      {
        label: (
          <span>
            {checkPermission(permissions?.documentCenter?.editDocTemplate) &&
              dataArray[dataIndex]?.is_custom && <>{editIconButton}</>}
          </span>
        ),
      },
      {
        label: (
          <span>
            {checkPermission(permissions?.documentCenter?.editDocTemplate) &&
              dataArray[dataIndex]?.is_custom && <>{addFieldsIconButton}</>}
          </span>
        ),
      },
      {
        label: (
          <span>
            {checkPermission(permissions?.documentCenter?.copyDocTemplate) && (
              <>{copyIconButton}</>
            )}
          </span>
        ),
      },
      {
        label: (
          <span>
            {checkPermission(permissions?.documentCenter?.menu) && (
              <>{deleteIconButton}</>
            )}
          </span>
        ),
      },
    ];

    setOptionsToDisplay(items);
    optionsOverlayPanelRef.current.toggle(e);
  };

  const dataTableColumns = [
    {
      name: '',
      options: {
        sort: false,
        filter: false,
        viewColumns: false,

        setCellProps: () => ({
          style: { minWidth: '180px', maxWidth: '180px' },
        }),
        customBodyRenderLite: dataIndex => {
          return (
            <span style={{ display: 'none' }} id={dataIndex}>
              <div className="flex align-items-center">
                <span>
                  <Menu
                    model={optionsToDisplay}
                    popup
                    ref={optionsOverlayPanelRef}
                    popupAlignment="left"
                    style={{ width: '8%' }}
                  />
                  <Button
                    type="button"
                    icon="pi pi-ellipsis-v"
                    rounded
                    text
                    size="small"
                    aria-label="Filter"
                    onClick={e =>
                      optionPopInteractionHandler(
                        dataArray[dataIndex],
                        dataIndex,
                        e
                      )
                    }
                  />
                </span>
              </div>
            </span>
          );
        },
      },
    },
    {
      name: 'source_system_ids',
      label: 'Source',
      options: {
        sort: true,
        viewColumns: false,
        filter: true,
        filterType: 'custom',
        setCellProps: () => ({
          style: { minWidth: '140px', maxWidth: '140px' },
        }),
        customFilterListOptions: {
          render: v => v.map(val => val?.source_name),
        },
        filterOptions: {
          display: (filterList, onChange, index, column) => {
            return (
              <Autocomplete
                name="source"
                multiple
                options={sourceOptions}
                onChange={(event, value) => {
                  filterList[index] = value?.length && value.map(val => val);
                  onChange(filterList[index], index, column);
                }}
                value={filterList[index]}
                getOptionLabel={option => option && option?.source_name}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Source"
                    InputLabelProps={{ shrink: true }}
                  />
                )}
              />
            );
          },
        },
        serverSide: true,
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        sort: true,
        filter: false,
        setCellProps: () => ({
          style: { minWidth: '140px', maxWidth: '140px' },
        }),
      },
    },
    {
      name: 'category',
      label: 'Category',
      options: {
        sort: false,
        filter: false,
        setCellProps: () => ({ style: { width: '12%' } }),
      },
    },
    {
      name: 'state',
      label: 'State',
      options: {
        sort: true,
        filter: false,
        setCellProps: () => ({ style: { width: '10%' } }),
      },
    },
    {
      name: 'is_lead_required',
      label: 'Lead Required',
      options: {
        sort: true,
        filter: false,
        setCellProps: () => ({ style: { width: '10%' } }),
      },
    },
    {
      name: 'created_at',
      label: 'Date Created',
      options: {
        sort: true,
        filter: false,
        setCellProps: () => ({ style: { width: '8%' } }),
      },
    },
    {
      name: 'template_name',
      label: 'Name',
      options: {
        sort: true,
        filter: false,
        setCellProps: () => ({ style: { width: '10%' } }),
      },
    },
    {
      name: 'document_url',
      label: 'Original File',
      options: {
        sort: false,
        filter: false,
        setCellProps: () => ({ style: { width: '15%' } }),
      },
    },
    {
      name: 'is_customer_visible',
      label: 'Show to customer',
      options: {
        sort: true,
        filter: false,
        filterType: 'checkbox',
        setCellProps: () => ({ style: { width: '10%' } }),
        customBodyRenderLite: dataIndex => {
          return (
            <span id={dataIndex}>
              <Checkbox
                color="primary"
                classes={{
                  root: classes.checkboxRoot,
                }}
                onChange={(event, value) =>
                  handleCheckbox(
                    event,
                    value,
                    dataIndex,
                    'customerProjectTemplates'
                  )
                }
                disabled={
                  !!checkBoxDisabledStates[dataIndex] ||
                  !!dataArray[dataIndex].is_signature_required
                }
                checked={
                  !!dataArray[dataIndex].is_customer_visible ||
                  !!dataArray[dataIndex].is_signature_required
                }
              />
            </span>
          );
        },
      },
    },
    {
      name: 'is_signature_required',
      label: 'Require Signature',
      options: {
        sort: true,
        filter: false,
        filterType: 'checkbox',
        setCellProps: () => ({ style: { width: '10%' } }),
        customBodyRenderLite: dataIndex => {
          return (
            <Checkbox
              color="primary"
              classes={{
                root: classes.checkboxRoot,
              }}
              onChange={async (event, value) => {
                signatureRequiredChangeHandler(
                  dataArray[dataIndex].template_id,
                  value
                );
                if (value && !dataArray[dataIndex].is_customer_visible) {
                  handleCheckbox(
                    event,
                    value,
                    dataIndex,
                    'customerProjectTemplates'
                  );
                }
              }}
              disabled={!!checkBoxDisabledStates[dataIndex]}
              checked={!!dataArray[dataIndex].is_signature_required}
            />
          );
        },
      },
    },
    {
      name: 'auto_create_project_document',
      label: 'Auto Create Doc',
      options: {
        sort: true,
        filter: false,
        setCellProps: () => ({ style: { width: '10%' } }),
        customBodyRenderLite: dataIndex => {
          return (
            <Checkbox
              color="primary"
              classes={{
                root: classes.checkboxRoot,
              }}
              onChange={(event, value) =>
                handleCheckbox(
                  event,
                  value,
                  dataIndex,
                  'AutoCreateProjectDocument'
                )
              }
              disabled={!!checkBoxDisabledStates[dataIndex]}
              checked={!!dataArray[dataIndex].auto_create_project_document}
            />
          );
        },
      },
    },
  ];
  const viewPermission = checkPermission(permissions?.documentCenter?.menu);
  const dataTableOptions = {
    download: false,
    print: false,
    resizableColumns: false,
    selectableRows: 'none',
    responsive: 'standard',
    filterType: 'multiselect',
    tableBodyMinHeight: '300px',
    page: searchQuery.offset / searchQuery.limit,
    count: templatesCount || 0,
    serverSide: true,
    textLabels: {
      body: {
        noMatch: !loading && !dataArray.length && 'No records found',
      },
    },
    onChangeRowsPerPage: numberOfRows => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        limit: numberOfRows,
        offset: 0,
      }));
    },
    onChangePage: currentPage => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        offset: currentPage * searchQuery.limit,
      }));
    },
    onColumnSortChange: (changedColumn, direction) => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        sortKey: getSortKey(changedColumn),
        order: tableOrder === 'desc' ? 'DESC' : 'ASC',
      }));
      setTableOrder(prev => (prev === 'desc' ? 'asc' : 'desc'));
    },
    onSearchChange: searchText => {
      handleSearch(searchText);
    },
    searchProps: {
      onKeyUp: e => {
        if (e.target.defaultValue && e.keyCode === 13) {
          setLoading(true);
          setSearchQuery(prevState => ({
            ...prevState,
            searchString: e.target.defaultValue,
            offset: 0,
          }));
        }
      },
    },
    onSearchClose: () => {
      setLoading(true);
      setSearchQuery(prevState => ({
        ...prevState,
        search_query: '',
      }));
    },
    rowsPerPage: searchQuery.limit,
    rowsPerPageOptions: [10, 20, 50, 100],
    setRowProps: (row, dataIndex, rowIndex) => {
      return {
        onMouseEnter: e => handleRowHover(e, row, rowIndex),
        onMouseLeave: e => handleRowHoverLeave(e, row, rowIndex),
        ...(viewPermission
          ? {
              onDoubleClick: () => {
                history.push(
                  `documentcenter/view/${dataArray && dataArray[dataIndex]?.template_id}`
                );
                //viewEditAvailabilityUpdate('view', dataIndex, dataArray && dataArray[dataIndex]);
              },
            }
          : {}),
      };
    },
    confirmFilters: true,

    // Calling the applyNewFilters parameter applies the selected filters to the table
    customFilterDialogFooter: (currentFilterList, applyNewFilters) => {
      return (
        <div style={{ marginTop: '40px' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleFilterSubmit(applyNewFilters)}
          >
            Apply Filters
          </Button>
        </div>
      );
    },

    onFilterChange: (
      column,
      filterList,
      type,
      changedColumnIndex,
      displayData
    ) => {
      let selectedFilterOptionsKey = [];
      switch (column) {
        case 'source_system_ids':
          selectedFilterOptionsKey = filterList[changedColumnIndex].map(
            val => val?.source_system_id
          );
          break;

        default:
          selectedFilterOptionsKey = [];
      }
      setSelectedFilters(prevState => {
        return { ...prevState, [column]: selectedFilterOptionsKey };
      });
    },
    pagination: true,
  };

  const getTemplateAddForm = () => {
    return (
      <Grid container spacing={2} direction="column">
        <Grid item xs={12}>
          <TextField
            id="template_name"
            label="Name"
            placeholder="Enter Name"
            required
            onChange={templateFormik.handleChange}
            onBlur={templateFormik.handleBlur}
            value={templateFormik?.values?.template_name}
            InputLabelProps={{ shrink: true }}
            error={
              templateFormik.errors.template_name &&
              templateFormik.touched.template_name
            }
            helperText={
              templateFormik.touched.template_name &&
              templateFormik.errors.template_name
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            id="source_system_id"
            name="source_system_id"
            options={sourceOptions}
            getOptionLabel={option => option && option?.source_name}
            renderInput={params => (
              <TextField
                {...params}
                label="Source"
                placeholder="Select"
                required
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loadingPosition ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
                error={
                  templateFormik.errors.source_system_id &&
                  templateFormik.touched.source_system_id
                }
                helperText={
                  templateFormik.touched.source_system_id &&
                  templateFormik.errors.source_system_id
                }
              />
            )}
            value={
              sourceOptions?.filter(
                source =>
                  source.source_system_id ===
                  templateFormik?.values?.source_system_id
              )[0] || ''
            }
            freeSolo
            onChange={async (event, value) => {
              templateFormik.setFieldValue(
                'source_system_id',
                value?.source_system_id
              );
              if (value?.source_system_id !== SOURCE_SYSTEMS.HOME_DEPOT) {
                templateFormik.setFieldValue('hd_doc_cd_number', null);
              }
              if (
                value?.source_system_id !== SOURCE_SYSTEMS.LUMBER_LIQUIDATOR
              ) {
                templateFormik.setFieldValue('ll_document_type', null);
              }
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            onBlur={templateFormik.handleBlur}
          />
        </Grid>
        {templateFormik?.values?.source_system_id ===
          SOURCE_SYSTEMS.HOME_DEPOT && (
          <Grid item xs={12}>
            <InputLabel id="doc-number">Doc Number</InputLabel>
            <Select
              labelId="doc-number"
              id="doc-number"
              value={templateFormik?.values?.hd_doc_cd_number}
              onChange={event => {
                templateFormik.setFieldValue(
                  'hd_doc_cd_number',
                  event.target.value
                );
              }}
            >
              {HD_DOC_NUMBERS_FOR_DOCUMENTS?.map(item => (
                <MenuItem value={item.id} key={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        )}
        {templateFormik?.values?.source_system_id ===
          SOURCE_SYSTEMS.LUMBER_LIQUIDATOR && (
          <Grid item xs={12}>
            <InputLabel id="doc-type">Doc Type</InputLabel>
            <Select
              labelId="doc-type"
              id="doc-type"
              value={templateFormik?.values?.ll_document_type}
              onChange={event => {
                templateFormik.setFieldValue(
                  'll_document_type',
                  event.target.value
                );
              }}
            >
              {LL_DOC_TYPE_FOR_DOCUMENTS?.map(item => (
                <MenuItem value={item} key={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        )}
        <Grid item xs={12}>
          <Autocomplete
            id="project_type_id"
            name="project_type_id"
            options={TemplateTypesOptions}
            getOptionLabel={option => option && option?.project_type}
            required
            renderInput={params => (
              <TextField
                {...params}
                label="Job Type"
                placeholder="Select"
                required
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loadingPosition ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            value={
              TemplateTypesOptions?.filter(
                template =>
                  template.project_type_id ===
                  templateFormik?.values?.project_type_id
              )[0] || 'All'
            }
            freeSolo
            onChange={async (event, value) => {
              templateFormik.setFieldValue(
                'project_type_id',
                value?.project_type_id
              );
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            onBlur={templateFormik.handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            id="project_category_id"
            name="project_category_id"
            required={
              templateFormik?.values?.project_category !== 'Any' &&
              !templateFormik?.values?.project_category_id
            }
            options={categoriesOptions}
            getOptionLabel={option => option && option?.category}
            renderInput={params => (
              <TextField
                {...params}
                label="Category"
                placeholder="Select"
                required
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loadingPosition ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            value={
              categoriesOptions?.filter(
                category =>
                  category.project_category_id ===
                  templateFormik?.values?.project_category_id
              )[0] || ''
            }
            freeSolo
            onChange={async (event, value) => {
              templateFormik.setFieldValue(
                'project_category_id',
                value?.project_category_id
              );
              templateFormik.setFieldValue('project_category', value?.category);
            }}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            onBlur={templateFormik.handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <InputLabel
            required={true}
            shrink
            id="state"
            className={classes.label}
          >
            State
          </InputLabel>
          <Select
            labelId="state"
            name="state_code"
            value={templateFormik?.values?.state_code}
            onChange={event => {
              templateFormik.setFieldValue('state_code', event.target.value);
            }}
            onBlur={templateFormik.handleBlur}
            displayEmpty={true}
            variant="standard"
            classes={{ select: classes.sourceSelect }}
            error={
              templateFormik.touched.state_code &&
              templateFormik.errors.state_code
            }
          >
            <MenuItem value={null}>All</MenuItem>
            {states.map((state, index) => (
              <MenuItem key={index} value={state.state_code}>
                {state.state_name}
              </MenuItem>
            ))}
          </Select>
          {templateFormik.touched.state_code &&
            templateFormik.errors.state_code && (
              <FormHelperText error>
                {templateFormik.errors.state_code}
              </FormHelperText>
            )}
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            id="is_lead_required"
            name="is_lead_required"
            options={isLeadRequiredDropdown}
            getOptionLabel={option => option?.value || ''}
            renderInput={params => (
              <TextField
                {...params}
                label="Lead Required"
                placeholder="Select"
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {loadingPosition ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            value={
              templateFormik?.values?.is_lead_required !== undefined
                ? isLeadRequiredDropdown.find(
                    opt => opt.id === templateFormik?.values?.is_lead_required
                  )
                : isLeadRequiredDropdown[2]
            }
            onChange={(event, value) => {
              templateFormik.setFieldValue(
                'is_lead_required',
                value?.id ?? null
              );
            }}
            freeSolo={false}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            onBlur={templateFormik.handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          {formAction === 'edit' && fileName}
        </Grid>
        {(formAction === 'add' || uploadNewTemplate) && (
          <Grid item xs={12}>
            <InputLabel
              shrink
              id="file-label"
              className={classes.label}
              required={formAction !== 'edit'}
            >
              Select Template
            </InputLabel>
            <Grid className={classes.imageInputWrapper}>
              <input
                type="file"
                className={classes.fileInput}
                accept=".pdf, .doc, .docx"
                required={formAction === 'add'}
                id="contained-button-file"
                onClick={event => {
                  event.target.value = null;
                }}
                onChange={event => {
                  if (event.target.files && event.target.files[0]) {
                    if (
                      ['pdf'].includes(
                        event.currentTarget.files[0].name.split('.').pop()
                      )
                    ) {
                      setFileName(event.currentTarget.files[0].name);
                      templateFormik.setFieldValue(
                        'file',
                        event.currentTarget.files[0]
                      );
                    } else {
                      setFileName(event.currentTarget.files[0].name);
                      templateFormik.setErrors({
                        file: 'Only .pdf files are allowed',
                      });
                    }
                  }
                }}
              />
              {fileName && (
                <Typography className={classes.fileName}>{fileName}</Typography>
              )}
              <label
                htmlFor="contained-button-file"
                className={classes.fileInputLabel}
              >
                Browse
              </label>
            </Grid>
            {fileName && templateFormik.errors.file && (
              <FormHelperText error>
                {templateFormik.errors.file}
              </FormHelperText>
            )}
          </Grid>
        )}
        input
        <Grid item xs={12} className="pl-0">
          <FormControlLabel
            name="is_custom"
            control={
              <Checkbox
                color="primary"
                classes={{
                  root: classes.checkboxRoot,
                }}
                value={templateFormik?.values?.is_custom || null}
                onChange={(event, value) => {
                  templateFormik.setFieldValue('is_custom', value);
                }}
                checked={templateFormik?.values?.is_custom}
              />
            }
            label="Editable"
            labelPlacement="right"
            classes={{
              root: classes.checkboxFormRoot,
              labelPlacementTop: classes.checkboxLabelPlacement,
              label: classes.checkboxLabel,
            }}
          />
          {checkPermission(permissions?.documentCenter?.showToCustomer) && (
            <>
              <FormControlLabel
                name="is_customer_visible"
                control={
                  <Checkbox
                    color="primary"
                    classes={{
                      root: classes.checkboxRoot,
                    }}
                    value={templateFormik?.values?.is_custom || null}
                    onChange={(event, value) => {
                      templateFormik.setFieldValue(
                        'is_customer_visible',
                        value
                      );
                    }}
                    disabled={templateFormik?.values?.is_signature_required}
                    checked={
                      templateFormik?.values?.is_customer_visible ||
                      templateFormik?.values?.is_signature_required
                    }
                  />
                }
                label="Show to customer"
                labelPlacement="right"
                classes={{
                  root: classes.checkboxFormRoot,
                  labelPlacementTop: classes.checkboxLabelPlacement,
                  label: classes.checkboxLabel,
                }}
              />
            </>
          )}
          {checkPermission(permissions?.documentCenter?.requireSignature) && (
            <>
              <FormControlLabel
                name="is_signature_required"
                control={
                  <Checkbox
                    color="primary"
                    classes={{
                      root: classes.checkboxRoot,
                    }}
                    value={templateFormik?.values?.is_custom || null}
                    onChange={(event, value) => {
                      templateFormik.setFieldValue(
                        'is_signature_required',
                        value ? 1 : 0
                      );
                      templateFormik.setFieldValue('is_customer_visible', true);
                    }}
                    checked={templateFormik?.values?.is_signature_required}
                  />
                }
                label="Require Signature on CX Portal"
                labelPlacement="right"
                classes={{
                  root: classes.checkboxFormRoot,
                  labelPlacementTop: classes.checkboxLabelPlacement,
                  label: classes.checkboxLabel,
                }}
              />
            </>
          )}
        </Grid>
      </Grid>
    );
  };

  // ****Validation Schema ****
  const templateValidationSchema = Yup.object().shape({
    template_name: Yup.string()
      .trim()
      .required('Required')
      .matches(
        /^[a-zA-Z0-9-_ ]+$/,
        'Template name can only contain letters, numbers, hyphens (-), and underscores (_)'
      ),
    source_system_id: Yup.number().typeError('Required').required('Required'),
    project_type_id: Yup.number().nullable(),
    project_category_id: Yup.number().nullable(),
    is_custom: Yup.boolean(),
    file: formAction === 'add' ? Yup.mixed().required('Required') : Yup.mixed(),
    state_code: Yup.string().nullable(),
    hd_doc_cd_number: Yup.number().nullable(),
    ll_document_type: Yup.string().nullable(),
    is_signature_required: Yup.boolean(),
  });
  // **** Formik Form Values ****
  const templateFormik = useFormik({
    initialValues: {
      template_name: '',
      source_system_id: null,
      project_type_id: null,
      project_category_id: null,
      project_category: null,
      file: null,
      is_custom: true,
      state_code: null,
      hd_doc_cd_number: null,
      ll_document_type: null,
      is_customer_visible: false,
      is_signature_required: false,
      is_lead_required: null,
    },
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      switch (formAction) {
        case 'add':
          const createTemplateAPIResponse = await createTemplate(
            templateFormik.values,
            setLoading,
            setAlert,
            setReloadForm,
            setIsOpen
          );
          if (createTemplateAPIResponse.template_id) {
            history.push(
              `/admin/documentcenter/edit/${createTemplateAPIResponse.template_id}`
            );
          }
          break;
        case 'edit':
          updateTemplateDetails(
            templateFormik.values.template_id,
            templateFormik.values,
            setLoading,
            setAlert,
            setReloadForm,
            setIsOpen,
            setIsChecked,
            isChecked
          );

          break;
        case 'copy':
          copyTemplate(
            templateFormik.values,
            setLoading,
            setAlert,
            setReloadForm,
            setIsOpen
          );
          break;
        default:
          break;
      }
      setReloadForm(false);
      resetForm();
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });
    },
    validationSchema: templateValidationSchema,
    enableReinitialize: true,
  });
  const addNewTemplate = () => {
    setHideAutoCreateCheckbox(true);
    setFormAction('add');
    templateFormik.resetForm();
    setDialogSettings(prevState => ({
      ...prevState,
      showButton2: true,
      button2Text: 'Create',
      title: 'Create New Template',
    }));
    setFileName('');
    setIsOpen(true);
  };
  const editTemplateDetails = (action, index, rowData) => {
    setFormAction('edit');
    setHideAutoCreateCheckbox(false);
    templateFormik?.setValues({
      template_id: rowData?.template_id,
      template_name: rowData?.template_name,
      source_system_id: rowData?.source_system_id,
      project_type_id:
        rowData?.project_type_id === 0 ? null : rowData?.project_type_id,
      project_category_id:
        rowData?.project_category_id === 0
          ? null
          : rowData?.project_category_id,
      project_category: rowData?.category,
      is_custom: rowData?.is_custom,
      hd_doc_cd_number: rowData?.hd_doc_cd_number
        ? rowData?.hd_doc_cd_number
        : null,
      state_code: rowData?.state_code ? rowData?.state_code : null,
      is_lead_required: mapYesNoDropdownValue(rowData?.is_lead_required),
      ll_document_type: rowData?.ll_document_type
        ? rowData?.ll_document_type
        : null,
      is_customer_visible: rowData?.is_customer_visible,
      is_signature_required: rowData?.is_signature_required,
    });
    setDialogSettings(prevState => ({
      ...prevState,
      showButton2: true,
      button2Text: 'Save',
      title: 'Edit Template',
    }));
    setFileName(rowData.template_file);
    setIsOpen(true);
  };
  const copyOldTemplate = () => {
    setFormAction('copy');
    setHideAutoCreateCheckbox(false);
    templateFormik?.setValues({
      template_id: copyOfTemplateData.template_id,
      template_name: copyOfTemplateData.template_name,
      source_system_id: copyOfTemplateData.source_system_id,
      project_type_id:
        copyOfTemplateData?.project_type_id === 0
          ? null
          : copyOfTemplateData?.project_type_id,
      project_category_id:
        copyOfTemplateData?.project_category_id === 0
          ? null
          : copyOfTemplateData?.project_category_id,
      is_custom: copyOfTemplateData.is_custom,
      hd_doc_cd_number: copyOfTemplateData?.hd_doc_cd_number
        ? copyOfTemplateData?.hd_doc_cd_number
        : null,
      state_code: copyOfTemplateData?.state_code
        ? copyOfTemplateData?.state_code
        : null,
      ll_document_type: copyOfTemplateData?.ll_document_type
        ? copyOfTemplateData?.ll_document_type
        : null,
      is_customer_visible: copyOfTemplateData?.is_customer_visible,
      is_signature_required: copyOfTemplateData?.is_signature_required,
      is_lead_required: mapYesNoDropdownValue(
        copyOfTemplateData?.is_lead_required
      ),
    });
    setDialogSettings(prevState => ({
      ...prevState,
      showButton2: true,
      button2Text: 'Save',
      title: 'Copy Template',
    }));
    setIsOpen(true);
  };
  //confirm Clone
  const confirmClone = oldTemplateInfo => {
    setCopyOfTemplateData(oldTemplateInfo);
    setConfirmDialog({
      ...confirmDialog,
      isOpen: true,
      title: `You need to create a duplicate copy of this template to edit.
     Would you like to create one? `,
      header: 'Alert',
      button2Text: 'Create',
    });
  };
  //confirm Delete
  const confirmDelete = (data, index) => {
    setTemplateId({
      template_id: data.template_id,
    });
    setConfirmDeleteDialog({
      ...confirmDeleteDialog,
      isOpen: true,
      title: 'Are you sure you want to delete this document?',
      header: 'Delete Document',
      button2Text: 'Delete',
    });
    setRowIndex(index);
  };

  const onConfirmDeleteDialog = () => {
    setLoading(true);
    setReloadForm(false);
    deleteDocument(
      templateId?.template_id,
      setLoading,
      setAlert,
      setReloadForm,
      setConfirmDeleteDialog
    );
  };
  return (
    <>
      <Toast ref={toast} />
      <Grid container spacing={2} direction="column">
        {alert.exists && (
          <Grid item>
            {' '}
            <Alert />
          </Grid>
        )}
        <Grid
          container
          item
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <Grid item>
            <PageHeader
              pageTitle="Documents"
              breadCrumbArray={templatesBreadcrumb}
            />
          </Grid>

          {checkPermission(permissions?.documentCenter?.addDocTemplate) && (
            <Grid item classes={{ root: classes.selfAlignGrid }}>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={addNewTemplate}
                >
                  Create New
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid item classes={{ root: classes.dataTableWrapper }}>
          {loading ? (
            <PFTableLoader />
          ) : (
            <GenericDataTable
              title="Templates"
              columns={dataTableColumns}
              data={dataArray}
              options={dataTableOptions}
            />
          )}
        </Grid>
      </Grid>
      <GenericDialog
        fullwidth
        isOpen={isOpen}
        handleClose={() => {
          setIsOpen(false);
          setConfirmDialog({
            ...confirmDialog,
            isOpen: false,
          });
          templateFormik?.handleReset();
        }}
        handleSave={templateFormik.handleSubmit}
        dialogSettings={dialogSettings}
        disabledButton2={
          !templateFormik?.isValid ||
          !templateFormik?.touched ||
          !templateFormik?.dirty ||
          (templateFormik?.dirty && !templateFormik?.isValid)
        }
        disabledButton1={false}
      >
        <form> {getTemplateAddForm()}</form>
      </GenericDialog>
      {/* Confirmation dialog for withdraw/acknowledge/cancel/contactoffice */}
      <GenericConfirmationDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
        onConfirmDialog={copyOldTemplate}
      />
      {/* Confirmation dialog for delete */}
      <GenericConfirmationDialog
        confirmDialog={confirmDeleteDialog}
        setConfirmDialog={setConfirmDeleteDialog}
        onConfirmDialog={onConfirmDeleteDialog}
      />
    </>
  );
};

export default Templates;
