import react, { useMemo, useState, useRef, useEffect } from 'react';
import moment from 'moment-timezone';
import {
  DataTable,
  Column,
  Dropdown,
  Calendar,
  InputText,
  Button,
  Tooltip,
  Skeleton,
  ProgressSpinner,
} from 'primereact';
import { Paginator } from 'primereact/paginator';

import {
  getDataByCube,
  getQueryResult,
} from '../../Widget/service/widget.service';
import { getTableQuery, prepareQueryWithFilters } from '../helpers/query';
import { FiltersOperators } from '../../../../constants';
import { formatDateTime, formatTimeHM } from '../../../../utils/Helpers';
import { TruncatedText } from '../../../shared/PFPrime/TruncatedText';
import { INVENTORY_ADJUSTMENT_OBJECT_ID, INVENTORY_ADJUSTMENT_PROJECT_OBJECT_ID, INVENTORY_ADJUSTMENT_PROJECT_TABLE, INVENTORY_ADJUSTMENT_TABLE, INVENTORY_ADJUSTMENT_TYPE, INVENTORY_ADJUSTMENT_TYPE_PROJECT } from '../constant/constant';

const FILTER_OPTIONS = [
  { label: 'Equals', value: 'equals', dateType: true, isMaster: true },
  { label: 'Not Equals', value: 'notEquals', isMaster: true },
  { label: 'Contains', value: 'contains', isMaster: true },
  { label: 'Not Contains', value: 'notContains', isMaster: true },
  { label: 'Starts With', value: 'startsWith' },
  {
    label: 'Ends With',
    value: 'endsWith',
  },
];
const DATE_FILTER_OPTIONS = [
  { label: 'In Between', value: 'equals', dateType: true, isMaster: true },
];

const getFilterOptions = (type, isMaster) => {
  if (type == 'time') return DATE_FILTER_OPTIONS;
  if (isMaster) return FILTER_OPTIONS.filter(item => item.isMaster);
  return FILTER_OPTIONS;
};

const initialInnerPagination = { first: 0, currentPage: 0, perPage: 50 };

const WidgetGroupedTableRenderer = ({
  widgetDetails,
  data: modifiedData,
  tablePagination,
  // onTableChangeHandler, // TODO: FOR FILTER
  annotation,
  totalCount,
  rowsPerPageOptions = true,
  orderBy,
  setOrderBy,
  setPaginator,
  paginator,
  filterDetails,
  filters = [],
  homeFilterDateDimension = [],
  tabGrpDep,
}) => {
  const [tableDataHeader, setTableDataHeader] = useState([]);
  const [tableAnnotation, setTableAnnotation] = useState();
  const [tableData, setTableData] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const [expandedRowsCount, setExpandedRowCount] = useState(0);
  const [innerPaginator, setInnerPaginator] = useState(initialInnerPagination);
  const [triggerApi, setTriggerApi] = useState(false);

  // TODO: FOR FILTER
  /* const [masterData, setMasterData] = useState({});
  const [isMaterDataFetched, setIsMaterDataFetched] = useState(false); */
  const [isLazyLoaded, setIsLazyLoaded] = useState(true);

  if (
    !Array.isArray(widgetDetails?.table_configuration) ||
    !widgetDetails?.table_configuration.length
  )
    return null;

  const headingKeys = [];
  const tableConfiguration = [];
  widgetDetails?.table_configuration.forEach(record => {
    if (!headingKeys.includes(record.measureName)) {
      headingKeys.push(record.measureName);
      tableConfiguration.push(record);
    }
  });
  // TODO: FOR FILTER
  /* if (!isMaterDataFetched) {
    setIsMaterDataFetched(true);
    const annotationList =
      annotation && Object.keys(annotation)?.length ? Object.keys(annotation) : [];
    const masterPromissList = annotationList?.map((dimensions) => {
      const [tableName, columnName] = dimensions.split('.');
      return getDataByCube(tableName, columnName);
    });
    Promise.all(masterPromissList).then((response) => {
      const masterResponse = {};
      annotationList.forEach((record, index) => {
        if (response[index]?.length > 0) {
          masterResponse[record] = response[index];
        }
      });
      setMasterData(masterResponse);
    });
  }
  const getFilterTypeDetails = (type, options) => {
    switch (type) {
      case 'dropdown':
        return (
          <Dropdown
            value={options.value}
            options={masterData[options.field].map((record) => record.value)}
            onChange={(e) => options.filterCallback(e.value, options.index)}
            placeholder="Select One"
            className="p-column-filter"
            showClear
          />
        );
        break;

      case 'time':
        return (
          <Calendar
            value={options.value}
            onChange={(e) => {
              options.filterCallback(e.value, options.index);
            }}
            dateFormat="mm-dd-yy"
            placeholder="mm-dd-yyyy - mm-dd-yyyy"
            selectionMode="range"
            showIcon
          />
        );
        break;

      default:
        return (
          <InputText
            value={options.value || ''}
            onChange={(e) => options.filterCallback(e.target.value, options.index)}
            placeholder="Search"
          />
        );
        break;
    }
  }; */
  const formatRowData = (obj, key, localAnnotation) => {
    if (obj[key] == 'Invalid date') {
      obj[key] = '';
    } else if (
      obj?.[key] &&
      (annotation?.[key]?.type == 'time' ||
        tableAnnotation?.[key]?.type == 'time' ||
        localAnnotation?.[key]?.type == 'time')
    ) {
      if (
        annotation?.[key]?.meta?.isDateTimeField ||
        tableAnnotation?.[key]?.meta?.isDateTimeField ||
        localAnnotation?.[key]?.meta?.isDateTimeField
      ) {
        // add Z as timezone only when timezone is not present
        obj[key] = formatDateTime(`${obj[key]}Z`);
      } else if (
        annotation?.[key]?.meta?.isTimeField ||
        tableAnnotation?.[key]?.meta?.isTimeField ||
        localAnnotation?.[key]?.meta?.isTimeField
      ) {
        obj[key] = formatTimeHM(`${obj[key]}Z`);
      } else {
        obj[key] = moment(`${obj[key]}`).format('MM-DD-YYYY');
      }
    } else if (
      annotation?.[key]?.meta?.format ||
      tableAnnotation?.[key]?.meta?.format ||
      localAnnotation?.[key]?.meta?.format
    ) {
      const formatter = eval(
        annotation?.[key]?.meta?.format ||
          tableAnnotation?.[key]?.meta?.format ||
          localAnnotation?.[key]?.meta?.format
      );
      obj[key] = formatter(obj[key]);
    }
    return obj;
  };
  /* const refreshTableCount = async (headerRowsArr) => {
    return await Promise.all(
      headerRowsArr.map(async (each, index) => {
        const response = await fillRowData(each);
        return {
          ...each,
          total: response.total,
        };
      })
    );
  }; */
  const refreshTableCount = async headerRowsArr => {
    // TODO: we can optimize using blendingQuery feature of CubeJS in which we can send array of queries in single API call and get the result. But right now current CubeJSversion is not supporting blendingQuery.
    return await Promise.all(
      headerRowsArr.map(async (each, index) => {
        const promises = [];
        const aggrData = {};
        if (
          widgetDetails?.report_group_by_aggr &&
          Object.entries(widgetDetails?.report_group_by_aggr).length
        ) {
          for (const key in widgetDetails?.report_group_by_aggr) {
            if (widgetDetails?.report_group_by_aggr[key]?.measureSummarizedBy) {
              aggrData[
                widgetDetails?.report_group_by_aggr[key].measureSummarizedBy
              ] = 0;
              promises.push(
                fillRowAggregateData(each, [
                  widgetDetails?.report_group_by_aggr[key].measureSummarizedBy,
                ])
              );
            }
          }
        }
        const result = await Promise.all(promises); // for all aggregation
        if (Array.isArray(result) && result.length) {
          result.forEach(record => {
            if (record) {
              const { data } = record;
              if (
                Array.isArray(data) &&
                data.length &&
                data[0] &&
                'object' === typeof data[0]
              ) {
                Object.keys(data[0]).forEach(key => {
                  if (key in aggrData) {
                    aggrData[key] = data[0][key];
                  }
                });
              }
            }
          });
        }
        return {
          ...each,
          aggrData,
        };
      })
    );
  };
  const addFilterInQuery = (query, values) => {
    if (
      Array.isArray(query.filters) &&
      query.filters.length &&
      widgetDetails?.report_group_by?.dimension
    ) {
      const condition = {
        member: widgetDetails?.report_group_by?.dimension,
        values: [values[widgetDetails?.report_group_by?.dimension]?.toString()],
        operator: FiltersOperators[0].key,
      };
      query.filters.push(condition);
      if (Array.isArray(filters) && filters.length)
        query.filters = [...query.filters, ...filters];
    } else if (Array.isArray(filters) && filters.length)
      query.filters = filters;
    if (
      Array.isArray(homeFilterDateDimension) &&
      homeFilterDateDimension.length &&
      Array.isArray(query?.timeDimensions)
    ) {
      homeFilterDateDimension.forEach(timeRecord => {
        query.timeDimensions?.push({
          dimension: timeRecord.dimension,
          dateRange: timeRecord.dateRange,
        });
      });
    }
    return query;
  };
  const fillRowAggregateData = async (values, measures = []) => {
    const formattedQuery = getTableQuery({ ...widgetDetails, measures });
    let query = prepareQueryWithFilters({
      tablePagination,
      query: widgetDetails,
      formattedQuery,
      cubeResponse: [],
      filterDetails,
      orderByConfiguration: orderBy,
      paginator: initialInnerPagination,
    });
    query = addFilterInQuery(query, values);
    return await getQueryResult(query);
  };
  const fillRowData = async (values, isExpand = false) => {
    const table_configuration = [...tableConfiguration].filter(
      e => !e?.columnTypeAction
    );
    // incase group by field is not selected in table column
    if (
      !table_configuration.some(
        e => e.measureName === widgetDetails?.report_group_by?.dimension
      )
    ) {
      table_configuration.push({
        alice: values[widgetDetails?.report_group_by?.dimension],
        measureName: widgetDetails?.report_group_by?.dimension,
      });
    }
    const params = {
      ...(widgetDetails || {}),
      table_configuration,
      report_group_by: null,
    };
    const formattedQuery = getTableQuery(params);
    let query = prepareQueryWithFilters({
      tablePagination,
      query: params,
      formattedQuery,
      cubeResponse: [],
      filterDetails,
      orderByConfiguration: orderBy,
      paginator: isExpand ? initialInnerPagination : innerPaginator,
    });
    query.total = true;
    if (
      widgetDetails?.custom_properties?.reportTablePrimaryKey &&
      !query.dimensions.includes(
        widgetDetails.custom_properties.reportTablePrimaryKey
      )
    ) {
      query.dimensions.push(
        widgetDetails.custom_properties.reportTablePrimaryKey
      );
      query.dimensions.push(
        widgetDetails.custom_properties.reportTableSecondaryKey
      );
      if (
        INVENTORY_ADJUSTMENT_PROJECT_TABLE ===
          widgetDetails?.custom_properties?.selectedReportTable &&
        query?.dimensions?.includes(INVENTORY_ADJUSTMENT_TYPE_PROJECT)
      ) {
        query.dimensions.push(INVENTORY_ADJUSTMENT_PROJECT_OBJECT_ID);
      }
      if (
        INVENTORY_ADJUSTMENT_TABLE ===
          widgetDetails?.custom_properties?.selectedReportTable?.replace(
            /\s/g,
            ''
          ) &&
        query?.dimensions?.includes(INVENTORY_ADJUSTMENT_TYPE)
      ) {
        query.dimensions.push(INVENTORY_ADJUSTMENT_OBJECT_ID);
      }
    }
    query = addFilterInQuery(query, values);
    return await getQueryResult(query);
  };
  const reArrangeTableData = (param, data, isExpand = false) => {
    let copy = [];
    if (isExpand) {
      copy = [...tableDataHeader];
      const tableIndex = copy.findIndex(
        element =>
          element[widgetDetails?.report_group_by?.dimension] ===
          param[widgetDetails?.report_group_by?.dimension]
      );
      const headerGroupData = copy[tableIndex];
      const addData = data.map(e => ({ ...e, ...headerGroupData }));
      // to remove root
      copy.splice(tableIndex, 1);
      // to add at expand index
      copy.splice(tableIndex, 0, addData);
    } else {
      copy = [...tableData];
      // to copy header
      const tableHeader = tableDataHeader.find(
        element =>
          element[widgetDetails?.report_group_by?.dimension] ===
          param[widgetDetails?.report_group_by?.dimension]
      );
      // to add element at last in case of inner pagination
      const tableIndex = copy.findLastIndex(
        element =>
          element[widgetDetails?.report_group_by?.dimension] ===
          param[widgetDetails?.report_group_by?.dimension]
      );
      const addData = data.map(e => ({ ...e, ...tableHeader }));
      // to add at expand index
      copy.splice(tableIndex + 1, 0, addData);
    }
    setTableData(copy.flat());
  };
  const handleExpand = async rowData => {
    sessionStorage.removeItem('viewLinks');
    sessionStorage.removeItem('editLinks');
    let curr = rowData.data;
    if (expandedRows) {
      curr = rowData.data.length
        ? rowData.data.filter(
            element =>
              element[widgetDetails?.report_group_by?.dimension] !==
              expandedRows[0][widgetDetails?.report_group_by?.dimension]
          )
        : [];
    }
    if (!curr.length) {
      setExpandedRows(null);
      setTableData(tableDataHeader);
      return;
    }
    const response = await fillRowData(curr[0], true);
    setExpandedRowCount(response.total);
    setTableAnnotation(response?.annotation?.dimensions);
    reArrangeTableData(curr[0], response.data, true);
    setExpandedRows(curr);
  };
  const handleClickMore = async () => {
    setInnerPaginator({
      ...innerPaginator,
      currentPage: innerPaginator.currentPage + 1,
    });
    setTriggerApi(!triggerApi);
  };
  const bodyTemplate = (bodyData, measureName) => {
    const formatBody = formatRowData({ ...bodyData }, measureName);
    return formatBody[measureName];
  };
  const headerTemplate = headerData => {
    const headerDataCopy = formatRowData(
      { ...headerData },
      widgetDetails?.report_group_by?.dimension
    );
    const measures = [];
    if (
      widgetDetails?.report_group_by_aggr &&
      Object.entries(widgetDetails?.report_group_by_aggr).length
    ) {
      for (const key in widgetDetails?.report_group_by_aggr) {
        const measureAnnotation = {
          [widgetDetails?.report_group_by_aggr[key]?.measureSummarizedBy]:
            widgetDetails?.report_group_by_aggr[key],
        };
        const measureHeaderCopy = formatRowData(
          { ...headerDataCopy?.aggrData },
          widgetDetails?.report_group_by_aggr[key]?.measureSummarizedBy,
          measureAnnotation
        );
        measures.push({
          name:
            widgetDetails?.report_group_by_aggr[key]?.report_group_aggr_label ||
            widgetDetails?.report_group_by_aggr[key]?.measureName,
          value:
            measureHeaderCopy?.[
              widgetDetails?.report_group_by_aggr[key]?.measureSummarizedBy
            ],
        });
      }
    }
    return (
      <>
        <span className="font-bold w-6 text-base">
          {widgetDetails?.report_group_by?.group_by_label || (
            <span>
              {widgetDetails?.report_group_by?.groupName}{' '}
              {widgetDetails?.report_group_by?.alice}
            </span>
          )}{' '}
          - {headerDataCopy[widgetDetails?.report_group_by?.dimension]}
          {headerDataCopy[
            widgetDetails?.report_group_by?.primaryKeyMeasureName
          ] &&
            `(${headerDataCopy[widgetDetails?.report_group_by?.primaryKeyMeasureName]})`}
        </span>
        <span>
          <div
            className="w-11 flex flex-wrap gap-2"
            style={{ marginLeft: '37px' }}
          >
            {measures.map(({ name, value }, i) => (
              <small key={i}>
                <b>{name}</b> ({value || '-'})
              </small>
            ))}
          </div>
        </span>
      </>
    );
  };
  const footerTemplate = data => {
    return (
      expandedRowsCount -
        (innerPaginator.perPage * innerPaginator.currentPage +
          innerPaginator.perPage) >
        0 && (
        <td colSpan={tableConfiguration.length} className="surface-200 h-10">
          <div className="flex justify-content-center w-full">
            <Button
              label="more..."
              link
              onClick={() => handleClickMore(data)}
            />
          </div>
        </td>
      )
    );
  };
  const columnTemplate = (configuration, orderBy) => {
    return (
      <div className="flex gap-2">
        {configuration.alice}{' '}
        {widgetDetails?.order_by?.orderField === configuration.measureName &&
          (widgetDetails?.order_by?.orderBy === 'asc' ? (
            <>
              <Tooltip target=".asc" />
              <span>
                <i
                  className="pi pi-sort-amount-up asc"
                  data-pr-tooltip="ascending"
                ></i>
              </span>
            </>
          ) : (
            <>
              <Tooltip target=".desc" />
              <i
                className="pi pi-sort-amount-down desc"
                data-pr-tooltip="decending"
              ></i>
            </>
          ))}
      </div>
    );
  };
  // rearrange Table column
  useEffect(() => {
    if (
      Array.isArray(tableConfiguration) &&
      tableConfiguration.length &&
      Array.isArray(modifiedData) &&
      modifiedData.length
    ) {
      setIsLazyLoaded(true);
      refreshTableCount(modifiedData)
        .then(setTableDataHeader)
        .catch()
        .finally(() => setIsLazyLoaded(false));
    } else setIsLazyLoaded(false);
  }, [modifiedData, tabGrpDep]);
  // trigger fetch data
  useEffect(() => {
    if (Array.isArray(expandedRows) && expandedRows.length) {
      fillRowData(expandedRows[0])
        .then(({ data }) => {
          reArrangeTableData(expandedRows[0], data);
        })
        .catch();
    }
  }, [triggerApi]);

  const truncatedString = ({ text, maxLength = 20 }) => {
    return <TruncatedText text={text || ''} maxLength={maxLength} />;
  };

  return (
    <div
      style={{
        display: 'table',
        tableLayout: 'fixed',
        width: '100%',
      }}
    >
      <DataTable
        // key={Math.random()}
        responsive
        resizableColumns
        expandableRowGroups
        rowGroupMode="subheader"
        // sortMode="single"
        showClear={false}
        scrollable={true}
        scrollHeight={'700px'}
        loading={isLazyLoaded}
        value={tableData.length ? tableData : tableDataHeader}
        groupRowsBy={widgetDetails?.report_group_by?.dimension}
        expandedRows={expandedRows}
        onRowToggle={handleExpand}
        rowGroupHeaderTemplate={headerTemplate}
        rowGroupFooterTemplate={footerTemplate}
        tableStyle={{ minWidth: '50rem' }}
        /* onSort={(event) => {
          setIsLazyLoaded(true);
          setTableData([]);
          setExpandedRows(null);
          setOrderBy({ ...orderBy, columns: event.sortField, direction: event.sortOrder });
        }}
        sortField={orderBy?.columns}
        sortOrder={orderBy?.direction} */
        // TODO: FOR FILTER
        /* onFilter={(event) => {
          setTableData([]);
          setExpandedRows(null);
          onTableChangeHandler({
            ...tablePagination,
            filters: event.filters,
          });
          setPaginator({
            ...paginator,
            first: 0,
            currentPage: 0,
          });
        }}
        // filters={tablePagination.filters}
         */
        tableClassName="w-12"
      >
        {tableConfiguration.map((configuration, index) => (
          <Column
            key={index}
            showAddButton={false}
            showFilterOperator={false}
            style={{ width: '20%' }}
            field={configuration.measureName}
            // sortable={configuration.measureName ? true : false}
            header={columnTemplate(configuration, orderBy)}
            // TODO: FOR FILTER
            /* filterMatchModeOptions={
              filter={
                configuration?.measureName && annotation
                  ? annotation[configuration.measureName]?.meta?.hideColumFilter
                    ? false
                    : true
                  : false
              }
              configuration?.measureName &&
              annotation &&
              getFilterOptions(
                annotation[configuration.measureName]?.type,
                masterData[configuration.measureName]
              )
            }
            filterElement={(options) => {
              return masterData[configuration.measureName]
                ? getFilterTypeDetails('dropdown', options)
                : getFilterTypeDetails(annotation[configuration.measureName]?.type, options);
            }} */
            body={
              configuration?.columnTypeAction
                ? (params, index) =>
                    configuration.options.customBodyRenderLite(params, index)
                : annotation?.[configuration.measureName]?.meta?.isUrl
                  ? (params, index) => renderUrl(params, index)
                  : configuration?.measureName &&
                      tableAnnotation?.[configuration.measureName]?.meta
                        ?.isTruncate
                    ? (params, index) =>
                        truncatedString({
                          text: params[configuration.measureName],
                          maxLength:
                            tableAnnotation?.[configuration.measureName]?.meta
                              ?.isTruncate?.maxLength,
                        })
                    : bodyData =>
                        bodyTemplate(bodyData, configuration?.measureName)
            }
          />
        ))}
      </DataTable>
      {(tableData.length > 0 || tableDataHeader.length > 0) && (
        <Paginator
          first={paginator.first}
          rows={paginator.perPage}
          totalRecords={totalCount}
          onPageChange={event => {
            setIsLazyLoaded(true);
            setTableData([]);
            setExpandedRows(null);
            setPaginator({
              ...paginator,
              currentPage: event.page,
              perPage: event.rows,
              first: event.first,
            });
          }}
          rowsPerPageOptions={rowsPerPageOptions && [10, 25, 50, 100, 500]}
          template="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        />
      )}
    </div>
  );
};
export default WidgetGroupedTableRenderer;
