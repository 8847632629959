import { getPersonalizedProjectNumber } from './ProjectDetails.service';
export const callPersonalizedProjectNumber = async () => {
  try {
    const response = await getPersonalizedProjectNumber();
    if (response?.data) {
      return response?.data;
    }
  } catch (error) {
    console.error(error);
    return null;
  }
};
