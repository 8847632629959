import axios from 'axios';

import { URL_CONSTANTS } from '../../../constants/urlConstants';
export const getDefaultFilterList = async () => {
  try {
    const response = await axios.get(
      `${URL_CONSTANTS.API.BASE_URL}/admin-config/schedulerFilter/get-All-scheduler-info`
    );

    return response?.data?.data || [];
  } catch (error) {
    console.error(error);
  }
};
